import React from "react";
import { DataTypeSidebar } from "../../../../components/Sidebar/DataTypeSideBar";
import Navbar from "../../../../components/Navbar/Navbar";
import Footer from "../../../../components/Footer/Footer";
import { WeatherObservationRasterVariables } from "../../../../utils/Raster/Variables/Climate/ClimateRasterVariables";
import { RasterLayout } from "../../../../components/TimeseriesRasterExternalLayout/RasterLayout";
import { useFetchLayers } from "../../../../utils/FetchingHook";

export const WeatherObservationRaster = () => {
    const Layers = useFetchLayers();

    return (
        <main className="content px-0">
            <DataTypeSidebar
                timeseriesURL="/sectors/climate/weather-observations/timeseries"
                rasterURL="/sectors/climate/weather-observations/raster"
                externalURL="/sectors/climate/weather-observations/external"
                sectorName="Climate"
                type="raster"
                varList={WeatherObservationRasterVariables}
            />
            <Navbar />
            <div className="row justify-content-center mb-5">
                <div className="col-12 text-center">
                    <h2 className="h1 px-lg-10">Climate Sector - Weather Observation</h2>
                    <p className="lead px-lg-10">Rasta Data for Climate sector on Weather Observations can be found here.</p>
                </div>
            </div>
            <div className="row p-3">
                <RasterLayout varList={Layers} />
            </div>
            <Footer />
        </main>
    )
}