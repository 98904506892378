import React from "react"

export const CarbonVariables: string[] = []

export const ConsumptionVariables: string[] = []

export const LandVariables: string[] = []

export const OtherEmissionsVariables: string[] = []

export const PricesVariables: string[] = []

export const ProductionVariables: string[] = []

export const SoilVariables: string[] = []
