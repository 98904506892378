import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { object, string, ref } from 'yup'
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

type formValues = {
    username: string;
    email: string;
    password: string;
    confirmPW: string;
    organization: string;
    country: string;
    useOfData: string;
}

const validationSchema = object().shape({
    username: string().min(2, 'Name value should not be less than 2 characters').max(100, 'Name should not be more than 100 character').required('Name is required'),
    email: string().email('Invalid email').required('Email is required'),
    password: string().min(8, 'Password must be at least 8 characters long').required('Password is required'),
    confirmPW: string().oneOf([ref('password')], 'Password does not match').required('Confirm the password'),
    organization: string().min(2, 'Organization name should be more than 2 characters').required('Organization name is required'),
    country: string().min(4, "Please provide full country name").max(100, "Country name cannot exceed 100 characters").required('Provide your country name'),
    useOfData: string().min(5, "Provide the purpose of the data(at least 5-character description)").max(255, "Description should be less than 255 characters").required('Field is required'),
});

const Signup = () => {
    const navigate = useNavigate();
    const [error, setError] = useState<string | null>("")
    const [success, setSuccess] = useState<string | null>("")

    const formik = useFormik<formValues>({
        initialValues: {
            username: "",
            email: "",
            password: "",
            confirmPW: "",
            organization: "",
            country: "",
            useOfData: ""
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            if (success){
                setSuccess(null);
            }

            if (error){
                setError(null)
            }
            alert("Form submitted, click okay and receive a verification email if form is valid.");
            axios.post(`${process.env.REACT_APP_API_URL}/signup/`, values)
                .then(response => {
                    const top = document.getElementById('top');
                    top?.scrollIntoView({'behavior': 'smooth'});
                    if (response.data['status'] === "failed") {
                        setError(response.data['message']);
                        return
                    }
                })
                .catch(error => { 
                    alert("Oops, something went wrong")
                    return
                 })
        },
    })

    useEffect(() => {
        const token = localStorage.getItem('token')
        if (token){
            navigate('/sectors')
        }
    }, [])

    function handlePasswordEyeToggle(nm: string) {
        const pwField: HTMLInputElement = document.querySelector(`#${nm}`)!;
        if (pwField.type === "password") {
            pwField.type = "text";
        } else {
            pwField.type = "password";
        }
    }

    return (
        <>
            <main className="m-4 p-4" id="top">
                <section className="mt-5 mt-lg-0 bg-soft d-flex align-items-center">
                    <div className="container">
                        <p className="text-center">
                            <Link to="/" className="d-flex align-items-center justify-content-center">
                                <svg className="icon icon-xs me-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z" clipRule="evenodd"></path></svg>
                                Back to homepage
                            </Link>
                        </p>
                        <div className="row justify-content-center form-bg-image" data-background-lg="/static/assets/img/illustrations/signin.svg">
                            <div className="col-12 d-flex align-items-center justify-content-center">
                                <div className="bg-white shadow border-0 rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                                    {success ? (<div className="alert alert-success text-center" role="alert">{success}</div>) :
                                        error ? (<div className="alert alert-danger text-center" role="alert">{error}</div>) : null}
                                    <div className="text-center text-md-center mb-4 mt-md-0">
                                        <h1 className="mb-0 h3">
                                            Create Account
                                        </h1>
                                    </div>
                                    <form method="post" action="" className="mt-4" onSubmit={formik.handleSubmit}>
                                        <div className="form-group mb-2">
                                            <label htmlFor="email">User Name</label>
                                            <div className="input-group">
                                                <span className="input-group-text" id="basic-addon1">
                                                    <span className="fas fa-user-circle">
                                                        <svg className="icon icon-xs text-gray-600" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z" /></svg>
                                                    </span>
                                                </span>
                                                <input
                                                    type="text"
                                                    name="username"
                                                    id="username"
                                                    placeholder="Enter Name"
                                                    className="form-control"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.username}
                                                />
                                            </div>
                                            {formik.touched.username && formik.errors.username ? (
                                                <div className="alert alert-danger p-1 mt-1" role="alert">
                                                    {formik.errors.username}
                                                </div>
                                            ) : null}
                                        </div>
                                        <div className="form-group mb-2">
                                            <label htmlFor="organization">Organization</label>
                                            <div className="input-group">
                                                <span className="input-group-text" id="basic-addon2">
                                                    <span className="fas fa-user-circle">
                                                        <svg className="icon icon-xs text-gray-600" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512"><path d="M48 0C21.5 0 0 21.5 0 48V464c0 26.5 21.5 48 48 48h96V432c0-26.5 21.5-48 48-48s48 21.5 48 48v80h96c26.5 0 48-21.5 48-48V48c0-26.5-21.5-48-48-48H48zM64 240c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V240zm112-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H176c-8.8 0-16-7.2-16-16V240c0-8.8 7.2-16 16-16zm80 16c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H272c-8.8 0-16-7.2-16-16V240zM80 96h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16zm80 16c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H176c-8.8 0-16-7.2-16-16V112zM272 96h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H272c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16z" /></svg>
                                                    </span>
                                                </span>
                                                <input
                                                    type="text"
                                                    name="organization"
                                                    id="organization"
                                                    placeholder="Enter Organization Name"
                                                    className="form-control"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.organization}
                                                />
                                            </div>
                                            {formik.touched.organization && formik.errors.organization ? (
                                                <div className="alert alert-danger p-1 mt-1" role="alert">
                                                    {formik.errors.organization}
                                                </div>
                                            ) : null}
                                        </div>
                                        <div className="form-group mb-2">
                                            <label htmlFor="country">Country</label>
                                            <div className="input-group">
                                                <span className="input-group-text" id="basic-addon2">
                                                    <span className="fas fa-user-circle">
                                                        <svg className="icon icon-xs" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M64 32C64 14.3 49.7 0 32 0S0 14.3 0 32V64 368 480c0 17.7 14.3 32 32 32s32-14.3 32-32V352l64.3-16.1c41.1-10.3 84.6-5.5 122.5 13.4c44.2 22.1 95.5 24.8 141.7 7.4l34.7-13c12.5-4.7 20.8-16.6 20.8-30V66.1c0-23-24.2-38-44.8-27.7l-9.6 4.8c-46.3 23.2-100.8 23.2-147.1 0c-35.1-17.6-75.4-22-113.5-12.5L64 48V32z" /></svg>
                                                    </span>
                                                </span>
                                                <input
                                                    type="text"
                                                    name="country"
                                                    id="country"
                                                    placeholder="Enter Your Country Name"
                                                    className="form-control"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.country}
                                                />
                                            </div>
                                            {formik.touched.country && formik.errors.country ? (
                                                <div className="alert alert-danger p-1 mt-1" role="alert">
                                                    {formik.errors.country}
                                                </div>
                                            ) : null}
                                        </div>
                                        <div className="form-group mb-2">
                                            <label htmlFor="email">Email</label>
                                            <div className="input-group">
                                                <span className="input-group-text" id="basic-addon3">
                                                    <span className="fas fa-user-circle">
                                                        <svg className="icon icon-xs" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z" /></svg>
                                                    </span>
                                                </span>
                                                <input
                                                    type="email"
                                                    name="email"
                                                    id="email"
                                                    placeholder="example@mail.com"
                                                    className="form-control"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.email}
                                                />
                                            </div>
                                            {formik.touched.email && formik.errors.email ? (
                                                <div className="alert alert-danger p-1 mt-1" role="alert">
                                                    {formik.errors.email}
                                                </div>
                                            ) : null}
                                        </div>
                                        <div className="form-group">
                                            <div className="form-group mb-2">
                                                <label htmlFor="password">Password</label>
                                                <div className="input-group">
                                                    <span className="input-group-text" id="basic-addon2">
                                                        <span className="fas fa-unlock-alt">
                                                            <svg className="icon icon-xs text-gray-600" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M144 144v48H304V144c0-44.2-35.8-80-80-80s-80 35.8-80 80zM80 192V144C80 64.5 144.5 0 224 0s144 64.5 144 144v48h16c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V256c0-35.3 28.7-64 64-64H80z" /></svg>
                                                        </span>
                                                    </span>
                                                    <input
                                                        type="password"
                                                        name="password"
                                                        id="password"
                                                        placeholder="Enter Password"
                                                        className="form-control"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.password}
                                                    />
                                                    <div className="input-group-text" data-password="false" onClick={() => handlePasswordEyeToggle("password")}>
                                                        <Link to='#'>
                                                            <span className="password-eye"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye" viewBox="0 0 16 16"><path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"></path><path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"></path></svg></span>
                                                        </Link>
                                                    </div>

                                                </div>
                                                {formik.touched.password && formik.errors.password ? <div className="alert alert-danger p-1 mt-1" role="alert">{formik.errors.password}</div> : null}
                                            </div>
                                            <div className="form-group mb-2">
                                                <label htmlFor="confirmPW">Confirm Password</label>
                                                <div className="input-group">
                                                    <span className="input-group-text" id="basic-addon2">
                                                        <span className="fas fa-unlock-alt">
                                                            <svg className="icon icon-xs text-gray-600" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M144 144v48H304V144c0-44.2-35.8-80-80-80s-80 35.8-80 80zM80 192V144C80 64.5 144.5 0 224 0s144 64.5 144 144v48h16c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V256c0-35.3 28.7-64 64-64H80z" /></svg>
                                                        </span>
                                                    </span>
                                                    <input
                                                        type="password"
                                                        name="confirmPW"
                                                        id="confirmPW"
                                                        placeholder="Confirm Password"
                                                        className="form-control"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.confirmPW}
                                                    />
                                                    <div className="input-group-text" data-password="false" onClick={() => handlePasswordEyeToggle("confirmPW")}>
                                                        <Link to='#'>
                                                            <span className="password-eye"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye" viewBox="0 0 16 16"><path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"></path><path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"></path></svg></span>
                                                        </Link>
                                                    </div>
                                                </div>
                                                {formik.touched.confirmPW && formik.errors.confirmPW ? <div className="alert alert-danger p-1 mt-1" role="alert">{formik.errors.confirmPW}</div> : null}
                                            </div>
                                        </div>
                                        <div className="form-group mb-2">
                                            <label htmlFor="useOfData">May we know your purpose for the data?</label>
                                            <div className="input-group">
                                                <textarea
                                                    name="useOfData"
                                                    id="useOfData"
                                                    placeholder="Purpose of Data"
                                                    className="form-control"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.useOfData}
                                                />
                                            </div>
                                            {formik.touched.useOfData && formik.errors.useOfData ? (
                                                <div className="alert alert-danger p-1 mt-1" role="alert">
                                                    {formik.errors.useOfData}
                                                </div>
                                            ) : null}
                                        </div>
                                        <div className="d-grid">
                                            <button type="submit" name="signup" className="btn btn-gray-800">Sign UP</button>
                                        </div>
                                    </form>
                                    <br />
                                    <div className="d-flex justify-content-center align-items-center">
                                        <span className="fw-normal">
                                            Already registered?
                                            <Link to="/login" className="fw-bold"> Login</Link>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    )
}

export default Signup;