import React from "react";
import { DataTypeSidebar } from "../../../../components/Sidebar/DataTypeSideBar";
import Navbar from "../../../../components/Navbar/Navbar";
import Footer from "../../../../components/Footer/Footer";
import { ClimateProjectionsVariables } from "../../../../utils/Timeseries/Variables/Climate/ClimateTimeseriesVariables";
import { TimeseriesLayout } from "../../../../components/TimeseriesRasterExternalLayout/TimeseriesLayout";
import { ClimateProjectionsLocations } from "../../../../utils/Timeseries/Locations/Climate/ClimateLocations";
import { ClimateProjectionsRasterVariables } from "../../../../utils/Raster/Variables/Climate/ClimateRasterVariables";

export const ClimateProjectionTimeseries = () => {
    return (
        <main className="content px-2">
            <DataTypeSidebar
                timeseriesURL="/sectors/climate/climate-projections/timeseries"
                rasterURL="/sectors/climate/climate-projections/raster"
                externalURL="/sectors/climate/climate-projections/external"
                sectorName="Climate"
                type="timeseries"
                varList={ClimateProjectionsRasterVariables}
            />
            <Navbar />
            <div className="row justify-content-center mb-5">
                <div className="col-12 text-center">
                    <h2 className="h1 px-lg-10">Climate Sector - Climate Projection</h2>
                    <p className="lead px-lg-10">Timeseries Data for Climate sector on climate projection can be found here.</p>
                </div>
            </div>
            
            <TimeseriesLayout varList={ClimateProjectionsVariables} locationList={ClimateProjectionsLocations} />
            <Footer />
        </main>
    )
}