import React from "react";
import { Route, Routes } from "react-router-dom";
import NotFound from "../../../pages/404Page/404page";
import { WaterAccessTimeseries } from "../../../pages/Sectors/Water/Access/WaterAccessTimeseries";
import { WaterAccessRaster } from "../../../pages/Sectors/Water/Access/WaterAccessRaster";
import { WaterAccessExternal } from "../../../pages/Sectors/Water/Access/WaterAccessExternal";
import { AquiferSystemsTimeseries } from "../../../pages/Sectors/Water/AquiferSytems/AquiferSystemsTimeseries";
import { AquiferSystemsRaster } from "../../../pages/Sectors/Water/AquiferSytems/AquiferSystemsRaster";
import { AquiferSystemsExternal } from "../../../pages/Sectors/Water/AquiferSytems/AquiferSystemsExternal";
import { WaterConsumptionTimeseries } from "../../../pages/Sectors/Water/Consumption/WaterConsumptionTimeseries";
import { WaterConsumptionRaster } from "../../../pages/Sectors/Water/Consumption/WaterConsumptionRaster";
import { WaterConsumptionExternal } from "../../../pages/Sectors/Water/Consumption/WaterConsumptionExternal";
import { DamsTimeseries } from "../../../pages/Sectors/Water/Dams/DamsTimeseries";
import { DamsRaster } from "../../../pages/Sectors/Water/Dams/DamsRaster";
import { DamsExternal } from "../../../pages/Sectors/Water/Dams/DamsExternal";
import { DemandTimeseries } from "../../../pages/Sectors/Water/Demand/DemandTimeseries";
import { DemandRaster } from "../../../pages/Sectors/Water/Demand/DemandRaster";
import { DemandExternal } from "../../../pages/Sectors/Water/Demand/DemandExternal";
import { EcosystemTimeseries } from "../../../pages/Sectors/Water/Ecosystem/EcosystemTimeseries";
import { EcosystemRaster } from "../../../pages/Sectors/Water/Ecosystem/EcosystemRaster";
import { EcosystemExternal } from "../../../pages/Sectors/Water/Ecosystem/EcosystemExternal";
import { LakesTimeseries } from "../../../pages/Sectors/Water/Lakes/LakesTimeseries";
import { LakesRaster } from "../../../pages/Sectors/Water/Lakes/LakesRaster";
import { LakesExternal } from "../../../pages/Sectors/Water/Lakes/LakesExternal";
import { WaterSourcesTimeseries } from "../../../pages/Sectors/Water/Sources/WaterSourcesTimeseries";
import { WaterSourcesRaster } from "../../../pages/Sectors/Water/Sources/WaterSourcesRaster";
import { WaterSourcesExternal } from "../../../pages/Sectors/Water/Sources/WaterSourcesExternal";
import { WatershedsTimeseries } from "../../../pages/Sectors/Water/Watersheds/WatershedsTimeseries";
import { WatershedsRaster } from "../../../pages/Sectors/Water/Watersheds/WatershedsRaster";
import { WaterShedsExternal } from "../../../pages/Sectors/Water/Watersheds/WatershedsExternal";
import { WaterOverview } from "../../Overview/WaterOverview";

const WaterRoutes = () => {
    return (
        <Routes>
            <Route path="" element={<WaterOverview />} />
            <Route path="access/timeseries" element={<WaterAccessTimeseries />} />
            <Route path="access/raster" element={<WaterAccessRaster />} />
            <Route path="access/raster/:name" element={<WaterAccessRaster />} />
            <Route path="access/external" element={<WaterAccessExternal />} />
            <Route path="aquifer-systems/timeseries" element={<AquiferSystemsTimeseries />} />
            <Route path="aquifer-systems/raster" element={<AquiferSystemsRaster />} />
            <Route path="aquifer-systems/raster/:name" element={<AquiferSystemsRaster />} />
            <Route path="aquifer-systems/external" element={<AquiferSystemsExternal />} />
            <Route path="consumption/timeseries" element={<WaterConsumptionTimeseries />} />
            <Route path="consumption/raster" element={<WaterConsumptionRaster />} />
            <Route path="consumption/raster/:name" element={<WaterConsumptionRaster />} />
            <Route path="consumption/external" element={<WaterConsumptionExternal />} />
            <Route path="dams/timeseries" element={<DamsTimeseries />} />
            <Route path="dams/raster" element={<DamsRaster />} />
            <Route path="dams/raster/:name" element={<DamsRaster />} />
            <Route path="dams/external" element={<DamsExternal />} />
            <Route path="demand/timeseries" element={<DemandTimeseries />} />
            <Route path="demand/raster" element={<DemandRaster />} />
            <Route path="demand/raster/:name" element={<DemandRaster />} />
            <Route path="demand/external" element={<DemandExternal />} />
            <Route path="ecosystem/timeseries" element={<EcosystemTimeseries />} />
            <Route path="ecosystem/raster" element={<EcosystemRaster />} />
            <Route path="ecosystem/raster/:name" element={<EcosystemRaster />} />
            <Route path="ecosystem/external" element={<EcosystemExternal />} />
            <Route path="lakes/timeseries" element={<LakesTimeseries />} />
            <Route path="lakes/raster" element={<LakesRaster />} />
            <Route path="lakes/raster/:name" element={<LakesRaster />} />
            <Route path="lakes/external" element={<LakesExternal />} />
            <Route path="sources/timeseries" element={<WaterSourcesTimeseries />} />
            <Route path="sources/raster" element={<WaterSourcesRaster />} />
            <Route path="sources/raster/:name" element={<WaterSourcesRaster />} />
            <Route path="sources/external" element={<WaterSourcesExternal />} />
            <Route path="watersheds/timeseries" element={<WatershedsTimeseries />} />
            <Route path="watersheds/raster" element={<WatershedsRaster />} />
            <Route path="watersheds/raster/:name" element={<WatershedsRaster />} />
            <Route path="watersheds/external" element={<WaterShedsExternal />} />
            <Route path="*" element={<NotFound />} />
        </Routes>
    )
}

export default WaterRoutes;