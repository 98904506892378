import React, { useEffect, useRef } from "react";
import { DataTypeSidebar } from "../../../../components/Sidebar/DataTypeSideBar";
import Navbar from "../../../../components/Navbar/Navbar";
import Footer from "../../../../components/Footer/Footer";
import { ExternalLayout } from "../../../../components/TimeseriesRasterExternalLayout/ExternalLayout";
import { WeatherObservationUrls } from "../../../../utils/External/urls/Climate/ClimateExternalURLS";
import { WeatherObservationRasterVariables } from "../../../../utils/Raster/Variables/Climate/ClimateRasterVariables";

export const WeatherObservationExternal = () => {
    const top = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (top.current) {
            top.current.scrollIntoView()
        }
    }, [])

    return (
        <main className="content px-0" ref={top}>
            <DataTypeSidebar
                timeseriesURL="/sectors/climate/weather-observations/timeseries"
                rasterURL="/sectors/climate/weather-observations/raster"
                externalURL="/sectors/climate/weather-observations/external"
                sectorName="Climate"
                type="external"
                varList={WeatherObservationRasterVariables}
            />
            <Navbar />
            <div className="row justify-content-center mb-5">
                <div className="col-12 text-center">
                    <h2 className="h1 px-lg-10">Climate Sector - Weather Observations</h2>
                    <p className="lead px-lg-10">Get all external data relating to Weather Observations in the climate sector by visiting the link(s) listed below.</p>
                </div>
            </div>
            <div className="card card-body shadow border-0 mx-3">
                <ExternalLayout urlList={WeatherObservationUrls} />
            </div>
            <Footer />
        </main>
    )
}