import axios from "axios";
import React, {useEffect, useState} from "react"
import { Link, useNavigate } from "react-router-dom";

export const VerifyUser = () => {
    const [id, setId] = useState("");
    const [success, setSuccess] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string[]>([]);
    const navigate = useNavigate()

    useEffect(() => {
        const token = localStorage.getItem('token')
        if (token){
            navigate('/sectors')
        } else {
            const url = window.location.href.split('/');
            setId(url[url.length - 1])
        }
    }, [navigate])

    function checkUser() {
        axios.post(`${process.env.REACT_APP_API_URL}/verified/`, {'id': id})
        .then((response) => {
            setLoading(true)
            if ('success' in response.data){
                setLoading(false)
                setSuccess('success')
            } else {
                setLoading(false)
                setError(['error', response.data['error']])
            }
        })
        .catch(error => {
            alert("Oops, something went wrong")
            return
        })
        .finally(() => {
            setTimeout(() => {axios.post(`${process.env.REACT_APP_API_URL}/delete/`, {'id': id})}, 10000);
        })
    }

    
    return(
    <>
        <main>
            <section className="vh-100 d-flex align-items-center justify-content-center">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-10 text-center d-flex align-items-center justify-content-center">
                            { loading ? (
                            <div className="card card-body w-50 align-items-center">
                                <span>
                                    <svg className="icon icon-s" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M304 48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zm0 416a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM48 304a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm464-48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM142.9 437A48 48 0 1 0 75 369.1 48 48 0 1 0 142.9 437zm0-294.2A48 48 0 1 0 75 75a48 48 0 1 0 67.9 67.9zM369.1 437A48 48 0 1 0 437 369.1 48 48 0 1 0 369.1 437z"/></svg>
                                </span>
                                <p>Loading...</p>
                            </div>
                            ) : success === "success" ? (
                                <div className="card card-body w-50 align-items-center">
                                    <p>Your email has been Verified!, Click the link below to login</p>
                                    <Link to="/login" className="btn btn-success w-25">Login</Link>
                                </div>
                                ) : error[0] === "error" ? (
                                    <div className="card card-body w-50 align-items-center">
                                        <p>{ error[1] }</p>
                                        <Link to="/signup" className="btn btn-danger w-25">Signup again</Link>
                                    </div>
                                ) : (
                                <div className="card card-body w-50 align-items-center">
                                    <p>Click the button below to verify</p>
                                    <button className="btn btn-primary w-25" onClick={() => {checkUser()}}>Verify</button>
                                </div>
                                )}
                        </div>
                    </div>
                </div>
            </section>
        </main>
    </>
    )
}