import React from "react";
import { DataTypeSidebar } from "../../../../components/Sidebar/DataTypeSideBar";
import Navbar from "../../../../components/Navbar/Navbar";
import Footer from "../../../../components/Footer/Footer";
import { ExternalLayout } from "../../../../components/TimeseriesRasterExternalLayout/ExternalLayout";
import { InfrastructureUrls } from "../../../../utils/External/urls/MacroEconomic/MacroEconomicExternaURLS";
import { InfrastructureRasterVariables } from "../../../../utils/Raster/Variables/MacroEconomics/MacroEconomicsRasterVariables";

export const InfrastructureExternal = () => {
    return (
        <main className="content px-2">
            <DataTypeSidebar
                timeseriesURL="/sectors/macro-economics/infrastructure/timeseries"
                rasterURL="/sectors/macro-economics/infrastructure/raster"
                externalURL="/sectors/macro-economics/infrastructure/external"
                sectorName="Macro-Economics"
                type="external"
                varList={InfrastructureRasterVariables}
            />
            <Navbar />
            <div className="row justify-content-center mb-5">
                <div className="col-12 text-center">
                    <h2 className="h1 px-lg-10">Macro-Economics Sector - Infrastructure</h2>
                    <p className="lead px-lg-10">Get all external data relating to infrastructure in the macro-economics sector by visiting the link(s) listed below.</p>
                </div>
            </div>
            <div className="card card-body shadow border-0 mx-3">
                <ExternalLayout urlList={InfrastructureUrls} />
            </div>
            <Footer />
        </main>
    )
}