import React, { useEffect, useRef } from "react";
import Navbar from "../Navbar/Navbar";
import { Overview } from "./Overview";
import Footer from "../Footer/Footer";
import { WaterSidebar } from "../Sidebar/WaterSidebar";
import { WaterSources } from "../../utils/Timeseries/DataSource/WaterSources";

export const WaterOverview = () => {
    const top = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (top.current) {
            top.current.scrollIntoView()
        }
    }, [])

    return (
        <main className="content px-0" ref={top}>
            <WaterSidebar />
            <Navbar/>
            <Overview
                sector="Water"
                sources={WaterSources}
            />
            <Footer />
        </main>
    )
}