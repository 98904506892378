import React from "react";

export const WaterAccessVariables: string[] = []

export const AquiferSystemsVariables: string[] = []

export const WaterConsumptionVariables: string[] = []

export const DamsVariables: string[] = []

export const DemandVariables: string[] = []

export const EcosystemsVariables: string[] = []

export const LakesVariables: string[] = [
    "Flow"
]

export const SourcesVariables: string[] = []

export const WatershedsVariables: string[] = []
