import React, { useEffect, useRef } from "react"
import Navbar from "../Navbar/Navbar"
import { Overview } from "./Overview"
import Footer from "../Footer/Footer"
import { EnergySidebar } from "../Sidebar/EnergySidebar"
import { EnergySources } from "../../utils/Timeseries/DataSource/EnergySources"

export const EnergyOverview = () => {
    const top = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (top.current) {
            top.current.scrollIntoView()
        }
    }, [])

    return (
        <main className="content px-0" ref={top}>
            <EnergySidebar />
            <Navbar />
            <Overview
                sector="Energy"
                sources={EnergySources}
            />
            <Footer />
        </main>
    )
}