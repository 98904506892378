import React from "react";
import Datepicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.module.css'
import './TimeRange.css'

interface TimeRangeProps {
    startDate: Date | null;
    endDate: Date | null;
    setStartDate: (date: Date | null) => void;
    setEndDate: (date: Date | null) => void;
}

const TimeRange = (props: TimeRangeProps) => {

    return (
        <>
            <div className="row g-2">
                <div className="col-md-5 col-xl-12 col-xxl-5 d-flex align-items-center">
                    <h6 className="mb-0 fw-bolder">Select a time period for the data</h6>
                </div>
                <div className="col-md-7 col-xl-12 col-xxl-7 ps-0 d-flex justify-content-center align-items-center ">
                    <div className="input-group">
                        <span className="input-group-text">
                            <svg className="icon icon-xxs" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clipRule="evenodd"></path></svg>
                        </span>
                        <Datepicker dropdownMode="scroll" selected={props.startDate} onChange={date => props.setStartDate(date)} name="startDate" isClearable required placeholderText="StartDate" className="form-control datepicker-input no-br" />
                        <Datepicker dropdownMode="scroll" selected={props.endDate} onChange={date => props.setEndDate(date)} maxDate={new Date()} name="endDate" isClearable required placeholderText="EndDate" className="form-control datepicker-input yes-brr" />
                    </div>
                </div>
            </div>
        </>
    )
}

export default TimeRange;