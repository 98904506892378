import React from "react";
export const MacroEconomicsSources: {
    title: string
    description: string
    dataPageURL: string
    sector: string
}[] = [
    {
        title: "GDP",
        description: "GDP Contribution Per Sector.",
        dataPageURL: "/sectors/macro-economics/gdp/timeseries",
        sector: "Macro-Economics Sector"
    },
    {
        title: "GDP Growth Rate",
        description: "Annual GDP Growth, Nominal GDP Prices, Real GDP Prices.",
        dataPageURL: "/sectors/macro-economics/gdp-growth-rate/timeseries",
        sector: "Macro-Economics Sector"
    },
    {
        title: "Income",
        description: "Income data on Rural, Urban, Female, Male.",
        dataPageURL: "/sectors/macro-economics/income/timeseries",
        sector: "Macro-Economics Sector"
    },
    {
        title: "Industry",
        description: "Energy intensive industry (iron & steel, non-ferrous metals, pulp & paper, chemical, food processing, etc.); and/or water intensive industry (garments & textile, meat production, beverages, etc.)",
        dataPageURL: "/sectors/macro-economics/industry/timeseries",
        sector: "Macro-Economics Sector"
    },
    {
        title: "Infrastructure",
        description: "Road networks ",
        dataPageURL: "/sectors/macro-economics/infrastructure/timeseries",
        sector: "Macro-Economics Sector"
    },
    {
        title: "Occupation",
        description: "Occupation data on citizens",
        dataPageURL: "/sectors/macro-economics/occupation/timeseries",
        sector: "Macro-Economics Sector"
    },
    {
        title: "Population",
        description: "Rural, Urban, Female, Male.",
        dataPageURL: "/sectors/macro-economics/population/timeseries",
        sector: "Macro-Economics Sector"
    }
].sort((a, b) => a.title.localeCompare(b.title))