import React from "react";
import Footer from "../../../components/Footer/Footer";
import Navbar from "../../../components/Navbar/Navbar";
import SidebarOverview from "../../../components/Sidebar/SidebarOverview";
import { AgricSources } from "../../../utils/Timeseries/DataSource/AgricultureSources";
import { Link } from "react-router-dom";
import { WaterSources } from "../../../utils/Timeseries/DataSource/WaterSources";
import { EnergySources } from "../../../utils/Timeseries/DataSource/EnergySources";
import { ClimateSources } from "../../../utils/Timeseries/DataSource/ClimateSources";
import { MacroEconomicsSources } from "../../../utils/Timeseries/DataSource/MacroEconomicsSources";

export const OverviewTimeseries = () => {
    const allTimeseries = [...AgricSources, ...WaterSources, ...EnergySources, ...MacroEconomicsSources, ...ClimateSources].sort((a, b) => a.title.localeCompare(b.title))
    return (
        <main className="content px-0">
            <SidebarOverview dataType="Timeseries" />
            <Navbar />
            <div className="row justify-content-center mb-5 mt-3">
                <div className="col-12 text-center">
                    <h2 className="h1 px-lg-5">Timeseries Data</h2>
                    <p className="lead px-lg-10 mb-1">Find below all timeseries data sources available in all various sectors.</p>
                    <p className="lead px-lg-10 mb-1">Visit the link of each data source of your interest and be led to the data page.</p>
                </div>
            </div>
            <div className="card card-body border-0 shadow mb-4 mb-xl-0">
                <p className="small pe-4 mb-2 text-info">To access a data source, click the button below each data source to get to the data page.</p>
                <hr />
                <ul className="list-group list-group-flush">
                    {allTimeseries.map((source, index) => {
                        return (
                            <li key={index} className="list-group-item d-flex align-items-center justify-content-between px-0 border-bottom">
                                <div>
                                    <div className="d-flex">
                                        <p className="fw-extrabold mb-0">{source.title}</p>&nbsp;<p className="mb-0">({source.sector})</p>
                                    </div>
                                    <p className="small pe-4 mb-2">{source.description}</p>
                                    <Link to={source.dataPageURL.replace("sectors", "filters/timeseries")}>
                                        <button type="button" className="btn btn-primary mb-2 text-white">
                                            Data Page
                                        </button>
                                    </Link>
                                </div>
                            </li>
                        )
                    })}
                </ul>
            </div>
            <Footer />
        </main>
    )
}