import React, { useEffect, useRef } from "react";
import { DataTypeSidebar } from "../../../../components/Sidebar/DataTypeSideBar";
import Navbar from "../../../../components/Navbar/Navbar";
import Footer from "../../../../components/Footer/Footer";
import { TimeseriesLayout } from "../../../../components/TimeseriesRasterExternalLayout/TimeseriesLayout";
import { EnergyInfrastructureVariables } from "../../../../utils/Timeseries/Variables/Energy/EnergyTimeseriesVariables";
import { EnergyInfrastructureLocations } from "../../../../utils/Timeseries/Locations/Energy/EnergyInfrastructureLocations";
import { EnergyInfrastructureRasterVariables } from "../../../../utils/Raster/Variables/Energy/EnergyRasterVariables";

export const EnergyInfrastructureTimeseries = () => {
    const top = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (top.current) {
            top.current.scrollIntoView()
        }
    }, [])

    return (
        <main className="content px-0" ref={top}>
            <DataTypeSidebar
                timeseriesURL="/sectors/energy/infrastructure/timeseries"
                rasterURL="/sectors/energy/infrastructure/raster"
                externalURL="/sectors/energy/infrastructure/external"
                sectorName="Energy"
                type="timeseries"
                varList={EnergyInfrastructureRasterVariables}
            />
            <Navbar />
            <div className="row justify-content-center mb-5">
                <div className="col-12 text-center">
                    <h2 className="h1 px-lg-10">Energy Sector - Infrastructure</h2>
                    <p className="lead px-lg-10">Timeseries Data for Energy sector on Infrastructure can be found here.</p>
                </div>
            </div>
            <TimeseriesLayout varList={EnergyInfrastructureVariables} locationList={EnergyInfrastructureLocations} />
            <Footer />
        </main>
    )
}