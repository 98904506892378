import React, { useState } from "react";
import { ExportButton } from "../ExportButton/ExportButton";
import TimeRange from "../TimeRange/TimeRange";
import { VariableList } from "../VariableList/VariableList";
import { Locations } from "../Locations/locations";
import './TR_Custom.css';
import { PreviewButton } from "../PreviewButoon/PreviewButton";

interface TimeseriesProps {
    varList: string[]
    locationList: string[]
}


export const TimeseriesLayout = ({ varList, locationList }: TimeseriesProps) => {
    const [startDate, setStartDate] = useState<Date | null>(null)
    const [endDate, setEndDate] = useState<Date | null>(null)
    const [tableData, setTableData] = useState<Array<Array<string>>>([])


    return (
        <div className="row varSelection justify-content-center" id="alert-noti">
            <div className="col-12 mb-4">
                <div className="ps-2 card notification-card border-0 shadow">
                    <div className="row">
                        <div className="col-12 col-sm-12 col-xl-6 p-3 pe-4">
                            <div className="card-body p-0 ps-2">
                                <div className="row g-2">
                                    <div className="col-xs-4 col-sm-5 ps-0">
                                        <h2 className="fs-5 fw-bold mb-0">Timeseries Data</h2>
                                    </div>
                                    <div className="p-0 col-xs-8 col-sm-7">
                                        <PreviewButton
                                            startDate={startDate}
                                            endDate={endDate}
                                            datafn={setTableData}
                                        />
                                        <ExportButton
                                            startDate={startDate}
                                            endDate={endDate}
                                        />
                                    </div>
                                </div>
                                <hr style={{color: "rgba(17, 24, 39, 0.625)"}} />
                                <TimeRange
                                    startDate={startDate}
                                    endDate={endDate}
                                    setStartDate={setStartDate}
                                    setEndDate={setEndDate}
                                />
                                <hr style={{color: "rgba(17, 24, 39, 0.625)"}} />
                                <div className="row g-2" id="selVar">
                                    <div className="col-sm-5 col-md-5 col-xl-5 col-xxl-5 d-flex">
                                        <h6 className="mb-0 fw-bolder">Select data variable(s)</h6>
                                    </div>
                                    <div className="p-0 col-sm-7 col-md-7 overflow-auto" style={{maxHeight: "10rem"}}>
                                        { varList.length === 0 ? (
                                            <p className="ps-3">No data variables available yet for this data source</p>
                                        ) : (
                                            <ul className="list-group list-group-flush">
                                                {varList.map((variable, index) => {
                                                    return (
                                                        <VariableList key={index + variable} name={variable} />
                                                    )
                                                })}
                                            </ul>
                                        )}
                                    </div>
                                </div>
                                <hr style={{color: "rgba(17, 24, 39, 0.625)"}} />
                                <div className="row g-2" id="selLoc">
                                    <div className="col-sm-5 col-md-5 col-xl-5 col-xxl-5 d-flex">
                                        <h6 className="mb-0 fw-bolder">Select location(s)</h6>
                                    </div>
                                    <div className="p-0 col-sm-7 col-md-7 overflow-auto" style={{maxHeight: "10rem", scrollbarWidth: "thin"}}>
                                        { locationList.length === 0 ? (
                                            <p className="ps-3">No location available yet.</p>
                                        ) : (
                                            <ul className="list-group list-group-flush">
                                                {locationList.map((location, index) => {
                                                    return (
                                                        <Locations key={index + location} name={location} />
                                                    )
                                                })}
                                            </ul>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-xl-6 p-3 pe-4 overflow-auto" style={{maxHeight: "700px"}}>
                        { tableData && tableData.length > 0 ? <div className="fw-bolder mb-2">For full data, click on the export button.</div> : null }
                            <div className="table-responsive h-100">
                                <table className="table table-centered table-nowrap mb-0 rounded border rounded-top">
                                    <thead className="thead-dark rounded-top" style={{position: "sticky", top: "0"}}>
                                        <tr>
                                            <th className="border-0 rounded-start">Timestamp</th>
                                            <th className="border-0">Variable</th>
                                            <th className="border-0">Value</th>
                                            <th className="border-0 rounded-end">Location</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tableData && tableData.length < 1 ? (
                                            <tr>
                                                <td>---</td>
                                                <td>---</td>
                                                <td>---</td>
                                                <td>---</td>
                                            </tr>
                                        ) : (
                                            tableData && tableData.map((data, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{data[0]}</td>
                                                        <td>{data[1]}</td>
                                                        <td>{data[2]}</td>
                                                        <td>{data[3]}</td>
                                                    </tr>
                                                )
                                            })
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}