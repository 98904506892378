import React from "react";

export const ClimateProjectionsUrls: string[][] = [
    ["Climate Projections", "http://kaop.co.ke/", "Kenya Agricultural Observatory Platform"]
]

export const WeatherForecastUrls: string[][] = [
    ["Weather Forecast", "http://kaop.co.ke/", "Kenya Agricultural Observatory Platform"]
]

export const WeatherObservationUrls: string[][] = [
    ["Weather Observations", "http://kaop.co.ke/", "Kenya Agricultural Observatory Platform"]
]
