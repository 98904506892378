import React from "react";
import axios from "axios";

export const getLayers = async (varName: string, ft: string) => {
    try{
        if (varName !== "raster"){
            // ds_cs: datastores && coveragestores
            let ds_cs;
            if (ft === "sh"){
                ds_cs = ["datastores", "featuretypes.xml", "featureType"];
            }
            else{
                ds_cs = ["coveragestores", "coverages.xml", "coverage"]
            }
            const response = await axios.get(`${process.env.REACT_APP_GEOSERVER_URL}/rest/workspaces/${process.env.REACT_APP_WORKSPACE_NAME}/${ds_cs[0]}/${varName}/${ds_cs[1]}`,
                {
                    headers: {
                        Accept: 'application/xml',
                        "Access-Control-Allow-Origin": "*",
                        "Cache-Control": "no-cache",
                    },
                    auth: {
                        username: `${process.env.REACT_APP_GEOSERVER_USERNAME}`,
                        password: `${process.env.REACT_APP_GEOSERVER_PASSWORD}`
                    }
                }
            )
            const parser = new DOMParser();
            const xmlDoc = parser.parseFromString(await response.data, 'application/xml');
            const types = xmlDoc.getElementsByTagName(ds_cs[2]);
            const layerNames = Array.from(types).map((type) =>
                type.getElementsByTagName('name')[0].textContent
            );
        return await layerNames;
        }
        return []
    }
    catch(error){
        alert("Oops, something went wrong")
            return
    }
};