import React from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import DataRepresentation from "../../components/DataRepresensation/DataRepresentation";

export const FilterBy = () => {
    return (
        <main className="content px-0">
            <Sidebar />
            <Navbar />
            <DataRepresentation visualizeURL="/filters/raster/overview" rawdataURL="/filters/timeseries/overview" externalURL="/filters/external/overview" />
            <Footer />
        </main>
    )
}