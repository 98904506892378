import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./Home.css"
import Footer from "../../components/Footer/Footer";

const Home = () => {
    const [user, setUser] = useState("");
    const [login, setLogin] = useState(false);

    useEffect(() => {
        const username = localStorage.getItem("username")
        if (username) {
            setLogin(true);
            if (username) {
                setUser(username);
            }
        }
    }, [])

    const logout = () => {
        localStorage.clear()
    }

    return (
        <main id="landingBG" style={{height: '100vh'}}>
            <header className="header-global">
                <nav id="navbar-main" aria-label="Primary navigation" className="navbar navbar-main navbar-expand-lg pt-4 navbar-dark">
                    <div className="container position-relative">
                        <div className="navbar-collapse me-auto" id="navbar_global" style={{ display: 'contents' }}>
                            <Link to="/">
                                <span className="logo-lg">
                                    <img src="assets/images/logoWhite.png" alt="EPIC Logo" width="85px" height="auto" style={{ paddingTop: 10 }}
                                    />
                                </span>
                            </Link>
                        </div>
                        <div className="ds-none d-flex align-items-center ms-auto">
                            <ul className="navbar-nav navbar-nav-hover align-items-lg-center">
                                <li className="nav-item me-2">
                                    <Link to="/sectors" className="nav-link btn btn-outline-white d-inline-flex align-items-center me-md-3">Sectors</Link>
                                </li>
                                <li className="nav-item me-2">
                                    <Link to="/about" className="nav-link btn btn-outline-white d-inline-flex align-items-center me-md-3">About</Link>
                                </li>
                                <li className="nav-item me-2">
                                    <Link to="/partners" className="nav-link btn btn-outline-white d-inline-flex align-items-center me-md-3">Partners</Link>
                                </li>
                                {login ? (
                                    <>
                                        <li className="nav-item dropdown ms-lg-3">
                                            <Link className="nav-link dropdown-toggle pt-1 px-0" to="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                <span className="mb-0 font-small fw-bold text-gray-900">
                                                    <svg className="icon icon-xs text-secondary" fill="currentColor" viewBox="0 0 448 512" xmlns="http://www.w3.org/2000/svg"><path d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z" /></svg>
                                                    <div className="text-secondary fw-extrabold fs-5 text-uppercase text-decoration-underline nav-link btn d-inline-flex align-items-center me-md-3">{user}</div>
                                                </span>
                                            </Link>
                                            <div className="dropdown-menu dashboard-dropdown dropdown-menu-end py-1">
                                                <a className="dropdown-item d-flex align-items-center" href={window.location.href} onClick={logout}>
                                                    <svg className="dropdown-icon text-danger me-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"></path></svg>
                                                    Logout
                                                </a>
                                            </div>
                                        </li>
                                    </>
                                ) : (
                                    <>
                                        <li className="nav-item me-2">
                                            <Link to="/login" className="nav-link btn btn-outline-white d-inline-flex align-items-center me-md-3">Login</Link>
                                        </li>
                                    </>
                                )}
                            </ul>
                        </div>
                    </div>
                </nav>
            </header>
            <section className="section-header overflow-hidden pt-7 pt-lg-8 pb-9 pb-lg-12 text-white landing" style={{height: '80vh'}}>
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center">
                            <p className="fs-4">Welcome to the</p>
                            <h1 className="fw-extrabold display-1">Climate Land-Use, Energy And Water Systems.</h1>
                            <div className="d-flex align-items-center justify-content-center mb-5">
                                <Link to="/sectors" className="btn btn-secondary d-inline-flex align-items-center me-4">
                                    <svg className="icon icon-xs me-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M11 3a1 1 0 10-2 0v1a1 1 0 102 0V3zM15.657 5.757a1 1 0 00-1.414-1.414l-.707.707a1 1 0 001.414 1.414l.707-.707zM18 10a1 1 0 01-1 1h-1a1 1 0 110-2h1a1 1 0 011 1zM5.05 6.464A1 1 0 106.464 5.05l-.707-.707a1 1 0 00-1.414 1.414l.707.707zM5 10a1 1 0 01-1 1H3a1 1 0 110-2h1a1 1 0 011 1zM8 16v-1h4v1a2 2 0 11-4 0zM12 14c.015-.34.208-.646.477-.859a4 4 0 10-4.954 0c.27.213.462.519.476.859h4.002z"></path></svg>
                                    Start Exploring Data
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <figure className="position-absolute bottom-0 left-0 w-100 d-none d-md-block mb-n2"><svg className="home-pattern" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3000 185.4"><path d="M3000,0v185.4H0V0c496.4,115.6,996.4,173.4,1500,173.4S2503.6,115.6,3000,0z"></path></svg></figure>
            </section>
            <div className="p-2" style={{height: '20vh'}}>
                <Footer />
            </div>
        </main>
    )
}

export default Home;