import React from "react";
import { DataTypeSidebar } from "../../../../components/Sidebar/DataTypeSideBar";
import Navbar from "../../../../components/Navbar/Navbar";
import Footer from "../../../../components/Footer/Footer";
import { LandRasterVariables } from "../../../../utils/Raster/Variables/Agriculture/AgriculturalRasterVariables";
import { RasterLayout } from "../../../../components/TimeseriesRasterExternalLayout/RasterLayout";
import { useFetchLayers } from "../../../../utils/FetchingHook";

export const LandRaster = () => {
    const Layers = useFetchLayers();
    
    return (
        <main className="content p-0">
            <DataTypeSidebar
                timeseriesURL="/sectors/agriculture/land/timeseries"
                rasterURL="/sectors/agriculture/land/raster"
                externalURL="/sectors/agriculture/land/external"
                sectorName="Agricultural / Land"
                type="raster"
                varList={LandRasterVariables}
            />
            <Navbar />
            <div className="row justify-content-center mb-5">
                <div className="col-12 text-center">
                    <h2 className="h1 px-lg-10">Agricultural / Land Sector - Land</h2>
                    <p className="lead px-lg-10">Timeseries Data for agricultural / land sector on land use or land cover can be found here.</p>
                </div>
            </div>
            <div className="row p-3">
                <RasterLayout varList={Layers} />
            </div>
            <Footer />
        </main>
    )
}