import React from "react";

export const CarbonUrls: string[][] = []

export const ConsumptionUrls: string[][] = [
    ["food, feed and Non-food/feed", "https://www.knbs.or.ke/", "Kenya National Bureau Of Statistics"],
    ["food, feed and Non-food/feed", "https://www.afdb.org/sites/default/files/documents/publications/food_balance_sheets_kenya_full_report.pdf", "food_balance_sheets_kenya_full_report.pdf"]
]

export const LandUrls: string[][] = [
    ["Crop", "https://statistics.kilimo.go.ke/", "Ministry of Agriculture & Livestock Development"],
    ["Forestry", "https://www.fao.org/faostat/en/#data", "Food and Agriculture Organization of the United Nations"],
]

export const OtherEmissionsUrls: string[][] = []

export const PricesUrls: string[][] = [
    ["Food", "https://kamis.kilimo.go.ke/", "Kamis"]
]

export const ProductionUrls: string[][] = [
    ["Crop", "https://statistics.kilimo.go.ke/", "Ministry of Agriculture and Livestock Development"],
    ["Foresry", "https://www.fao.org/faostat/en/#data", "Food and Agriculture Organization of the United Nations"]
]

export const SoilUrls: string[][] = [
    ["soil types, composition, etc.", "https://statistics.kilimo.go.ke/en/soil/", "Ministry of Agriculture and Livestock Development"]
]
