import React, { useEffect, useRef } from "react";
import { DataTypeSidebar } from "../../../../components/Sidebar/DataTypeSideBar";
import Navbar from "../../../../components/Navbar/Navbar";
import Footer from "../../../../components/Footer/Footer";
import { SoilVariables } from "../../../../utils/Timeseries/Variables/Agriculture/AgricultureTimeseriesVariables";
import { TimeseriesLayout } from "../../../../components/TimeseriesRasterExternalLayout/TimeseriesLayout";
import { SoilLocations } from "../../../../utils/Timeseries/Locations/Agriculture/SoilLocations";
import { SoilRasterVariables } from "../../../../utils/Raster/Variables/Agriculture/AgriculturalRasterVariables";

export const SoilTimeseries = () => {
    const top = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (top.current) {
            top.current.scrollIntoView()
        }
    }, [])

    return (
        <main className="content px-0" ref={top}>
            <DataTypeSidebar
                timeseriesURL="/sectors/agriculture/soil/timeseries"
                rasterURL="/sectors/agriculture/soil/raster"
                externalURL="/sectors/agriculture/soil/external"
                sectorName="Agricultural / Land"
                type="timeseries"
                varList={SoilRasterVariables}
            />
            <Navbar />
            <div className="row justify-content-center mb-5">
                <div className="col-12 text-center">
                    <h2 className="h1 px-lg-10">Agricultural / Land Sector - Soil</h2>
                    <p className="lead px-lg-10">Timeseries Data for agricultural / land sector on soil can be found here.</p>
                </div>
            </div>
           
           <TimeseriesLayout varList={SoilVariables} locationList={SoilLocations} />
            <Footer />
        </main>
    )
}