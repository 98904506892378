import React from 'react'
import Navbar from '../../components/Navbar/Navbar';
import { Link } from 'react-router-dom';
import './Partners.css'
import Footer from '../../components/Footer/Footer';
import CustomSidebar from '../../components/Sidebar/About&Partners';

const Partners = () => {
    return (
        <>
            <CustomSidebar />
            <Navbar />
            <section style={{padding: '0'}} className="section-header overflow-hidden text-white landing d-flex justify-content-center align-items-center" id="partnersPage">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center">
                            <h1 className="text-secondary fw-extrabold display-1">Our Partners</h1>
                            <p className="text-secondary fs-5">Click on the logo to know more about our partners</p>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className="row me-1 ms-1 mt-4 d-flex justify-content-center align-items-center">
                    <div className="col-6 col-sm-6 col-lg-3 scale">
                        <Link to="https://tahmo.org/" target='_blank'>
                            <div className="card bg-white shadow-soft text-primary rounded mb-4 bg-gray-300">
                                <div className="px-3 px-lg-4 py-5 text-center">
                                    <img className='icon icon-lg mb-3' src="assets/images/tahmo.png" alt="TAHMO Logo" />
                                </div>
                            </div>
                        </Link>
                    </div>

                    <div className="col-6 col-sm-6 col-lg-3">
                        <Link to="https://www.tudelft.nl/en/" target='_blank'>
                            <div className="card bg-white shadow-soft text-primary rounded mb-4 bg-gray-300">
                                <div className="px-3 px-lg-4 py-5 text-center">
                                    <img className='icon icon-lg mb-3' src="assets/images/tudelft.png" alt="TU-Delft Logo" />
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-6 col-sm-6 col-lg-3">
                        <Link to="https://vito.be/en" target='_blank'>
                            <div className="card bg-white shadow-soft text-primary rounded mb-4 bg-gray-300">
                                <div className="px-3 px-lg-4 py-5 text-center">
                                    <img className='icon icon-lg mb-3' src="assets/images/vito.png" alt="vito Logo" />
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-6 col-sm-6 col-lg-3">
                        <Link to="https://www.kalro.org/" target='_blank'>
                            <div className="card bg-white shadow-soft text-primary rounded mb-4 bg-gray-300">
                                <div className="px-3 px-lg-4 py-5 text-center">
                                    <img className='icon icon-lg mb-3' src="assets/images/kalro.jpg" alt="KALRO Logo" />
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-6 col-sm-6 col-lg-3">
                        <Link to="https://uenr.edu.gh/" target='_blank'>
                            <div className="card bg-white shadow-soft text-primary rounded mb-4 bg-gray-300">
                                <div className="px-3 px-lg-4 py-5 text-center">
                                    <img className='icon icon-lg mb-3' src="assets/images/UENR.png" alt="UENR Logo" />
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-6 col-sm-6 col-lg-3">
                        <Link to="https://abv.int/en/abvhome/" target='_blank'>
                            <div className="card bg-white shadow-soft text-primary rounded mb-4 bg-gray-300">
                                <div className="px-3 px-lg-4 py-5 text-center">
                                    <img className='icon icon-lg mb-3' src="assets/images/vba.jpg" alt="VBA Logo" />
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-6 col-sm-6 col-lg-3">
                        <Link to="https://www.kth.se/en" target="_blank">
                            <div className="card bg-white shadow-soft text-primary rounded mb-4 bg-gray-300">
                                <div className="px-3 px-lg-4 py-5 text-center">
                                    <img className='icon icon-lg mb-3' src="assets/images/KTH.jpg" alt="KTH Logo" />
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
            </section >
            <div className="px-2">
                <Footer />
            </div>
        </>
    )
}

export default Partners;