import React from "react";
import { DataTypeSidebar } from "../../../../components/Sidebar/DataTypeSideBar";
import Navbar from "../../../../components/Navbar/Navbar";
import { RasterLayout } from "../../../../components/TimeseriesRasterExternalLayout/RasterLayout";
import Footer from "../../../../components/Footer/Footer";
import { SourcesRasterVariables } from "../../../../utils/Raster/Variables/Water/WaterRasterVariables";
import { useFetchLayers } from "../../../../utils/FetchingHook";

export const WaterSourcesRaster = () => {
    const Layers = useFetchLayers();

    return (
        <main className="content p-0">
            <DataTypeSidebar
                timeseriesURL="/sectors/water/watersheds/timeseries"
                rasterURL="/sectors/water/watersheds/raster"
                externalURL="/sectors/water/watersheds/external"
                sectorName="Water"
                type="raster"
                varList={SourcesRasterVariables}
            />
            <Navbar />
            <div className="row justify-content-center mb-5">
                <div className="col-12 text-center">
                    <h2 className="h1 px-lg-10">Water Sector - Access</h2>
                    <p className="lead px-lg-10">Rasta Data for Water sector on water sources can be found here.</p>
                </div>
            </div>
            <div className="row p-3">
                <RasterLayout varList={Layers} />
            </div>
            <Footer />
        </main>
    )
}