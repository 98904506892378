import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Login from './pages/Login/Login';
import Sectors from './pages/Sectors/Sectors';
import Home from './pages/Home/Home'
import Signup from './pages/Signup/Signup';
import Partners from './pages/Partners/Partners';
import About from './pages/About/About';
import NotFound from './pages/404Page/404page';
import AgricultureRoutes from './components/Routes/AgricultureRoutes/AgricultureRoutes';
import WaterRoutes from './components/Routes/WaterRoutes/WaterRoutes';
import EnergyRoutes from './components/Routes/EnergyRoutes/EnergyRoutes';
import MacroEconomicsRoutes from './components/Routes/MacroEconomicsRoutes/MacroEconomicsRoutes';
import ClimateRoutes from './components/Routes/ClimateRoutes/ClimateRoutes';
import { FilterBy } from './pages/FilterBy/FilterBy';
import { FilterTimeseriesRoutes } from './components/Routes/FilterRoutes/TimeseriesRoutes';
import { FilterRasterRoutes } from './components/Routes/FilterRoutes/RasterRoutes';
import { FilterExternalRoutes } from './components/Routes/FilterRoutes/ExternalRoutes';
import { VerifyUser } from './pages/VerifyUser/VerifyUser';
import { ForgotPassword } from './pages/ForgotPass/ForgotPass';
import { Reset } from './pages/Reset/Reset';


function App() {

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/sectors" element={<Sectors />} />
        <Route path="/filters" element={<FilterBy />} />
        <Route path="/filters/timeseries/*" element={<FilterTimeseriesRoutes />} />
        <Route path="/filters/raster/*" element={<FilterRasterRoutes />} />
        <Route path="/filters/external/*" element={<FilterExternalRoutes />} />
        <Route path="/sectors/agriculture/*" element={<AgricultureRoutes />} />
        <Route path="/sectors/water/*" element={<WaterRoutes />} />
        <Route path="/sectors/energy/*" element={<EnergyRoutes />} />
        <Route path="/sectors/macro-economics/*" element={<MacroEconomicsRoutes />} />
        <Route path="/sectors/climate/*" element={<ClimateRoutes />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/partners" element={<Partners />} />
        <Route path="/about" element={<About />} />
        <Route path="/verify/:id" element={<VerifyUser />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset/:id" element={<Reset />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default App;
