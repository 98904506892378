
export const GDP_GRRasterVariables: string[] = []

export const GDPRasterVariables: string[] = []

export const IncomeRasterVariables: string[] = []

export const IndustryRasterVariables: string[] = [
    "Airports(sh)",
    "Burkina Faso Departments with Volta(sh)"
]

export const InfrastructureRasterVariables: string[] = [
    "Communication Channels(sh)",
    "Roads(sh)"
]

export const OccupationRasterVariables: string[] = []

export const PopulationRasterVariables: string[] = [
    "Administrative(sh)",
    "Kenya Populated Places(sh)",
    "Population(sh)",
    "Ghana Data(sh)",
    "Volta Cities(sh)"
]