import React from 'react'
import { Routes, Route } from 'react-router-dom'
import NotFound from '../../../pages/404Page/404page';
import { ClimateOverview } from '../../Overview/ClimateOverview';
import { ClimateProjectionTimeseries } from '../../../pages/Sectors/Climate/ClimateProjection/ClimateProjectionTimeseries';
import { WeatherForecastTimeseries } from '../../../pages/Sectors/Climate/WeatherForecast/WeatherForecastTimeseries';
import { WeatherObservationTimeseries } from '../../../pages/Sectors/Climate/WeatherObservation/WeatherObservationTimeseries';
import { WeatherForecastExternal } from '../../../pages/Sectors/Climate/WeatherForecast/WeatherForecastExternal';
import { ClimateProjectionExternal } from '../../../pages/Sectors/Climate/ClimateProjection/ClimateProjectionExternal';
import { WeatherObservationExternal } from '../../../pages/Sectors/Climate/WeatherObservation/WeatherObservationExternal';
import { WeatherObservationRaster } from '../../../pages/Sectors/Climate/WeatherObservation/WeatherObservationRaster';
import { WeatherForecastRaster } from '../../../pages/Sectors/Climate/WeatherForecast/WeatherForecastRaster';
import { ClimateProjectionRaster } from '../../../pages/Sectors/Climate/ClimateProjection/ClimateProjectionRaster';

const ClimateRoutes = () => {
    return (
        <Routes>
            <Route path="" element={<ClimateOverview />} />
            <Route path="weather-forecasts/timeseries" element={<WeatherForecastTimeseries />} />
            <Route path="weather-forecasts/raster" element={<WeatherForecastRaster />} />
            <Route path="weather-forecasts/raster/:name" element={<WeatherForecastRaster />} />
            <Route path="weather-forecasts/external" element={<WeatherForecastExternal />} />
            <Route path="climate-projections/timeseries" element={<ClimateProjectionTimeseries />} />
            <Route path="climate-projections/raster" element={<ClimateProjectionRaster />} />
            <Route path="climate-projections/raster/:name" element={<ClimateProjectionRaster />} />
            <Route path="climate-projections/external" element={<ClimateProjectionExternal />} />
            <Route path="weather-observations/timeseries" element={<WeatherObservationTimeseries />} />
            <Route path="weather-observations/raster" element={<WeatherObservationRaster />} />
            <Route path="weather-observations/raster/:name" element={<WeatherObservationRaster />} />
            <Route path="weather-observations/external" element={<WeatherObservationExternal />} />
            <Route path="*" element={<NotFound />} />
        </Routes>
    )
}

export default ClimateRoutes;