import React from "react";
import './VariableList.css'

interface ListProps {
    name: string
    handleVarSelection?: (varName: string) => void
}

export const VariableList = ({ name, handleVarSelection}: ListProps) => {
    
    return (
        <li className="hover-state p-1 ps-2 list-group-item d-flex align-items-center">
            <div>
                <div className="form-check form-switch">
                    {handleVarSelection? (
                        <input className="form-check-input varName" type="checkbox" id={name} name={name} onChange={() => {handleVarSelection(name)}} />
                    ) : (
                        <input className="form-check-input varName" type="checkbox" id={name} name={name} />
                    )}
                </div>
            </div>
            <div>
                <label className="form-check-label m-0 cursor" htmlFor={name}>{name}</label>
            </div>
        </li>
    )
}