import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import './Navbar.css'
import axios from "axios";

const Navbar = () => {
    const [user, setUser] = useState("");
    const [login, setLogin] = useState(false)
    const [logo, setLogo] = useState(false)
    const top = useRef<HTMLDivElement>(null)
    const [loggedOut, setLoggedOut] = useState("")

    useEffect(() => {
        const username = localStorage.getItem('username')
        if (username) {
            setLogin(true);
            if (username) {
                setUser(username);
            }
        }
        if (top.current) {
            top.current.scrollIntoView()
        }
    }, [loggedOut,])

    useEffect(() => {
        const urlArr = window.location.href.split('/')
        const pg_Name = urlArr[urlArr.length -1]
        if (pg_Name === 'about' || pg_Name === 'partners'){
            setLogo(true);
        }
    }, [])

    const logout = () => {
        localStorage.clear()
    }

    return (
        <>
            <nav className="navbar navbar-top navbar-expand navbar-dashboard navbar-dark p-0 d-sm-none d-lg-flex logo-none">
                <div className="container-fluid px-0" style={{height: '70px'}}>
                    <div className="ps-3 d-flex justify-content-between w-100" id="navbarSupportedContent">
                    {logo ? (
                            <div className="d-flex align-items-center d-lg-flex">
                                <Link to="/" className="nav-link d-flex align-items-center" style={{padding: '0'}}>
                                    <span className="logo-lg m-0">
                                        <img src="assets/images/logoWhite.png" alt="EPIC logo" style={{width: '3rem'}} />
                                    </span>
                                </Link>
                            </div>
                        ) : (<div></div>)}
                        <ul className="navbar-nav align-items-center">
                            <li className="nav-item me-1 bg-primary rounded bg-opacity-25 hover">
                                <Link to="/sectors" className="nav-link text-primary fw-bolder d-inline-flex align-items-center" style={{fontSize: 'medium'}}>Sectors</Link>
                            </li>
                            <li className="nav-item me-1 bg-primary rounded bg-opacity-25">
                                <Link to="/about" className="nav-link text-primary fw-bolder d-inline-flex align-items-center" style={{fontSize: 'medium'}}>About</Link>
                            </li>
                            <li className="nav-item me-1 bg-primary rounded bg-opacity-25">
                                <Link to="/partners" className="nav-link text-primary fw-bolder d-inline-flex align-items-center" style={{fontSize: 'medium'}}>Partners</Link>
                            </li>
                            {login ? (
                                <>
                                    <li className="nav-item dropdown ms-lg-3">
                                        <Link style={{fontSize: 'medium'}} className="nav-link dropdown-toggle pt-1 px-0" to="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            <span className="mb-0 font-small fw-bold text-gray-900">
                                                <div className="text-primary fw-bold fs-6 text-uppercase nav-link d-inline-flex align-items-center me-md-3">
                                                    <svg className="icon icon-xs text-gray-600" fill="currentColor" viewBox="0 0 448 512" xmlns="http://www.w3.org/2000/svg"><path d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z" /></svg>
                                                    &nbsp;
                                                    {user}
                                                </div>
                                            </span>
                                        </Link>
                                        <div className="dropdown-menu dashboard-dropdown dropdown-menu-end py-1">
                                            <a style={{fontSize: 'medium'}} className="dropdown-item d-flex align-items-center" href={window.location.href} onClick={() => { logout() }}>
                                                <svg className="dropdown-icon text-danger me-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"></path></svg>
                                                Logout
                                            </a>
                                        </div>
                                    </li>
                                </>
                            ) : (
                                <>
                                    <li className="nav-item me-1 bg-primary rounded bg-opacity-25">
                                        <Link to="/login" style={{fontSize: 'medium'}} className="nav-link text-primary fw-bolder d-inline-flex align-items-center">Login</Link>
                                    </li>
                                </>
                            )}
                        </ul>
                    </div>
                </div>
            </nav>
            {loggedOut && <div className="alert alert-success alert-dismissible fade show" style={{maxWidth: "50%"}} role="alert">
                <strong>{loggedOut}</strong>
                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                </div>}
        </>
    );
};

export default Navbar;
