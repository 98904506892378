import React from "react";

export const GDP_GRUrls: string[][] = [
    ["GDP Growth Rate", "https://www.centralbank.go.ke/statistics/national-accounts-statistics/", "Central Bank of Kenya"]
]

export const GDPUrls: string[][] = [
    ["GDP", "https://statistics.kilimo.go.ke/en/", "Ministry of Agriculture and Livestock Development"]
]

export const IncomeUrls: string[][] = [
    ["Income", "https://statistics.kilimo.go.ke/en/", "Ministry of Agriculture and Livestock Development"]
]

export const IndustryUrls: string[][] = []

export const InfrastructureUrls: string[][] = []

export const OccupationUrls: string[][] = []

export const PopulationUrls: string[][] = [
    ["Population and Census", "https://statistics.kilimo.go.ke/en/", "Ministry of Agriculture and Livestock Development"]
]
