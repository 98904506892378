import React, { useEffect, useRef } from "react";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import { AgricSidebar } from "../Sidebar/AgricSidebar";
import { AgricSources } from "../../utils/Timeseries/DataSource/AgricultureSources";
import { Overview } from "./Overview";

export const AgricultureOverview = () => {
    const top = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (top.current) {
            top.current.scrollIntoView()
        }
    }, [])

    return (
        <main className="content p-0" ref={top}>
            <AgricSidebar />
            <Navbar />
            <Overview
                sector="Agricultural / Land"
                sources={AgricSources}
            />
            <Footer />
        </main>
    )
}