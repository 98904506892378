import React from "react";
export const ClimateSources: {
    title: string
    description: string
    dataPageURL: string
    sector: string
}[] = [
    {
        title: "Weather Forecast",
        description: "Rainfall, Temperature, Relative Humidity, Solar Radiation, Atmospheric Pressure, Wind Speed and Direction",
        dataPageURL: "/sectors/climate/weather-forecasts/raster",
        sector: "Climate Sector"
    },
    {
        title: "Climate Projections",
        description: "Temperatures, Rainfall, Wind, Radiation, Humidity",
        dataPageURL: "/sectors/climate/climate-projections/raster",
        sector: "Climate Sector"
    },
    {
        title: "Weather Observation",
        description: "Rainfall, Temperature, Relative Humidity, Solar Radiation, Atmospheric Pressure, Wind Speed and Direction",
        dataPageURL: "/sectors/climate/weather-observations/raster",
        sector: "Climate Sector"
    },
    {
        title: "Historical Climate",
        description: "Historical changes in Climate",
        dataPageURL: "/sectors/climate/historical-climate/raster",
        sector: "Climate Sector"
    }
].sort((a, b) => a.title.localeCompare(b.title))