import React from "react";

export const EnergySources: {
    title: string
    description: string
    dataPageURL: string
    sector: string
}[] = [
    {
        title: "Access",
        dataSourceBodyID: "access-body",
        dataSourceHeaderID: "access-header",
        description: "Access to energy (clean cooking and electricity).",
        dataPageURL: "/sectors/energy/access/external",
        sector: "Energy Sector"
    },
    {
        title: "Consumption / Demand",
        dataSourceBodyID: "consumption-demand-body",
        dataSourceHeaderID: "consumption-demand-header",
        description: "Per capita consumption, sector consumption (residential sector, rural and urban), transmission and distribution efficiencies.",
        dataPageURL: "/sectors/energy/consumption-demand/external",
        sector: "Energy Sector"
    },
    {
        title: "Sources",
        dataSourceBodyID: "sources-body",
        dataSourceHeaderID: "sources-header",
        description: "Off-Grid (PV, Wind, Geothermal), Grid (Hydro, Thermal, PV), Biofuels and Fossil fuels.",
        dataPageURL: "/sectors/energy/sources/external",
        sector: "Energy Sector"
    },
    {
        title: "Infrastructure",
        dataSourceBodyID: "energy-infrastructure-body",
        dataSourceHeaderID: "energy-infrastructure-header",
        description: "Off-Grid (PV, Wind, Geothermal), Grid (Hydro, Thermal, PV), Biofuels and Fossil fuels.",
        dataPageURL: "/sectors/energy/infrastructure/external",
        sector: "Energy Sector"
    },
    {
        title: "Models",
        dataSourceBodyID: "models-body",
        dataSourceHeaderID: "models-header",
        description: "Energy planning, operational and investment models.",
        dataPageURL: "/sectors/energy/models/external",
        sector: "Energy Sector"
    },
    {
        title: "Projects",
        dataSourceBodyID: "projects-body",
        dataSourceHeaderID: "projects-header",
        description: "Objectives, period, partners and source of funding and reports.",
        dataPageURL: "/sectors/energy/projects/external",
        sector: "Energy Sector"
    },
].sort((a, b) => a.title.localeCompare(b.title))