import React from "react";
import { Route, Routes } from "react-router-dom";
import NotFound from "../../../pages/404Page/404page";
import { ProductionExternal } from "../../../pages/Sectors/Agriculture/Production/ProductionExternal";
import { ConsumptionExternal } from "../../../pages/Sectors/Agriculture/Consumption/ConsumptionExternal";
import { PricesExternal } from "../../../pages/Sectors/Agriculture/Prices/PricesExternal";
import { LandExternal } from "../../../pages/Sectors/Agriculture/Land/LandExternal";
import { CarbonExternal } from "../../../pages/Sectors/Agriculture/Carbon/CarbonExternal";
import { SoilExternal } from "../../../pages/Sectors/Agriculture/Soil/SoilExternal";
import { OtherEmissionsExternal } from "../../../pages/Sectors/Agriculture/OtherEmissions/OtherEmissionsExternal";
import { WaterAccessExternal } from "../../../pages/Sectors/Water/Access/WaterAccessExternal";
import { AquiferSystemsExternal } from "../../../pages/Sectors/Water/AquiferSytems/AquiferSystemsExternal";
import { WaterConsumptionExternal } from "../../../pages/Sectors/Water/Consumption/WaterConsumptionExternal";
import { DamsExternal } from "../../../pages/Sectors/Water/Dams/DamsExternal";
import { DemandExternal } from "../../../pages/Sectors/Water/Demand/DemandExternal";
import { EcosystemExternal } from "../../../pages/Sectors/Water/Ecosystem/EcosystemExternal";
import { LakesExternal } from "../../../pages/Sectors/Water/Lakes/LakesExternal";
import { WaterSourcesExternal } from "../../../pages/Sectors/Water/Sources/WaterSourcesExternal";
import { WaterShedsExternal } from "../../../pages/Sectors/Water/Watersheds/WatershedsExternal";
import { EnergyAccessExternal } from "../../../pages/Sectors/Energy/Access/EnergyAccessExternal";
import { ConsDemExternal } from "../../../pages/Sectors/Energy/ConsumptionDemand/EnergyConsDemExternal";
import { EnergyInfrastructureExternal } from "../../../pages/Sectors/Energy/Infrastructure/EnergyInfrastructureExternal";
import { ModelsExternal } from "../../../pages/Sectors/Energy/Models/ModelsExternal";
import { ProjectsExternal } from "../../../pages/Sectors/Energy/Projects/ProjectsExternal";
import { EnergySourceExternal } from "../../../pages/Sectors/Energy/Sources/EnergySourcesExternal";
import { GDPExternal } from "../../../pages/Sectors/MacroEconomics/GDP/GDPExternal";
import { GDP_GRExternal } from "../../../pages/Sectors/MacroEconomics/GDP_GR/GDP_GRExternal";
import { IncomeExternal } from "../../../pages/Sectors/MacroEconomics/Income/IncomeExternal";
import { IndustryExternal } from "../../../pages/Sectors/MacroEconomics/Industry/IndustryExternal";
import { InfrastructureExternal } from "../../../pages/Sectors/MacroEconomics/Infrastructure/InfrastructureExternal";
import { OccupationExternal } from "../../../pages/Sectors/MacroEconomics/Occupation/OccupationExternal";
import { PopulationExternal } from "../../../pages/Sectors/MacroEconomics/Population/PopulationExternal";
import { ClimateProjectionExternal } from "../../../pages/Sectors/Climate/ClimateProjection/ClimateProjectionExternal";
import { WeatherForecastExternal } from "../../../pages/Sectors/Climate/WeatherForecast/WeatherForecastExternal";
import { WeatherObservationExternal } from "../../../pages/Sectors/Climate/WeatherObservation/WeatherObservationExternal";
import { OverviewExternal } from "../../../pages/FilterBy/External/ExternalFilterOverview";

export const FilterExternalRoutes = () => {
    return (
        <Routes>
            <Route path="overview" element={<OverviewExternal />} />
            <Route path="agriculture/production/external" element={<ProductionExternal />} />
            <Route path="agriculture/consumption/external" element={<ConsumptionExternal />} />
            <Route path="agriculture/prices/external" element={<PricesExternal />} />
            <Route path="agriculture/land/external" element={<LandExternal />} />
            <Route path="agriculture/carbon/external" element={<CarbonExternal />} />
            <Route path="agriculture/soil/external" element={<SoilExternal />} />
            <Route path="agriculture/other-emissions/external" element={<OtherEmissionsExternal />} />
            <Route path="water/access/external" element={<WaterAccessExternal />} />
            <Route path="water/aquifer-systems/external" element={<AquiferSystemsExternal />} />
            <Route path="water/consumption/external" element={<WaterConsumptionExternal />} />
            <Route path="water/dams/external" element={<DamsExternal />} />
            <Route path="water/demand/external" element={<DemandExternal />} />
            <Route path="water/ecosystems/external" element={<EcosystemExternal />} />
            <Route path="water/lakes/external" element={<LakesExternal />} />
            <Route path="water/sources/external" element={<WaterSourcesExternal />} />
            <Route path="water/watersheds/external" element={<WaterShedsExternal />} />
            <Route path="energy/access/external" element={<EnergyAccessExternal />} />
            <Route path="energy/consumption-demand/external" element={<ConsDemExternal />} />
            <Route path="energy/infrastructure/external" element={<EnergyInfrastructureExternal />} />
            <Route path="energy/models/external" element={<ModelsExternal />} />
            <Route path="energy/projects/external" element={<ProjectsExternal />} />
            <Route path="energy/sources/external" element={<EnergySourceExternal />} />
            <Route path="macro-economics/gdp/external" element={<GDPExternal />} />
            <Route path="macro-economics/gdp-growth-rate/external" element={<GDP_GRExternal />} />
            <Route path="macro-economics/income/external" element={<IncomeExternal />} />
            <Route path="macro-economics/industry/external" element={<IndustryExternal />} />
            <Route path="macro-economics/infrastructure/external" element={<InfrastructureExternal />} />
            <Route path="macro-economics/occupation/external" element={<OccupationExternal />} />
            <Route path="macro-economics/population/external" element={<PopulationExternal />} />
            <Route path="climate/climate-projections/external" element={<ClimateProjectionExternal />} />
            <Route path="climate/weather-forecasts/raster" element={<WeatherForecastExternal />} />
            <Route path="climate/weather-observations/external" element={<WeatherObservationExternal />} />
            <Route path="*" element={<NotFound />} />
        </Routes >
    )
}