import React from 'react';
import { Routes, Route } from 'react-router-dom';
import NotFound from '../../../pages/404Page/404page';
import { EnergyOverview } from '../../Overview/EnergyOverview';
import { EnergyAccessTimeseries } from '../../../pages/Sectors/Energy/Access/EnergyAccessTimeseries';
import { EnergyAccessRaster } from '../../../pages/Sectors/Energy/Access/EnergyAccessRaster';
import { EnergyAccessExternal } from '../../../pages/Sectors/Energy/Access/EnergyAccessExternal';
import { ConsDemTimeseries } from '../../../pages/Sectors/Energy/ConsumptionDemand/EnergyConsDemTimeseries';
import { ConsDemRaster } from '../../../pages/Sectors/Energy/ConsumptionDemand/EnergyConsDemRaster';
import { ConsDemExternal } from '../../../pages/Sectors/Energy/ConsumptionDemand/EnergyConsDemExternal';
import { EnergyInfrastructureTimeseries } from '../../../pages/Sectors/Energy/Infrastructure/EnergyInfrastructureTimeseries';
import { EnergyInfrastructureRaster } from '../../../pages/Sectors/Energy/Infrastructure/EnergyInfrastructureRaster';
import { EnergyInfrastructureExternal } from '../../../pages/Sectors/Energy/Infrastructure/EnergyInfrastructureExternal';
import { ModelsTimeseries } from '../../../pages/Sectors/Energy/Models/ModelsTimeseries';
import { ModelsRaster } from '../../../pages/Sectors/Energy/Models/ModelsRaster';
import { ModelsExternal } from '../../../pages/Sectors/Energy/Models/ModelsExternal';
import { ProjectsTimeseries } from '../../../pages/Sectors/Energy/Projects/ProjectsTimeseries';
import { ProjectsRaster } from '../../../pages/Sectors/Energy/Projects/ProjectsRaster';
import { ProjectsExternal } from '../../../pages/Sectors/Energy/Projects/ProjectsExternal';
import { EnergySourceTimeseries } from '../../../pages/Sectors/Energy/Sources/EnergySourcesTimeseries';
import { EnergySourceRaster } from '../../../pages/Sectors/Energy/Sources/EnergySourcesRaster';
import { EnergySourceExternal } from '../../../pages/Sectors/Energy/Sources/EnergySourcesExternal';

const EnergyRoutes = () => {
    return (
        <Routes>
            <Route path="" element={<EnergyOverview />} />
            <Route path="access/timeseries" element={<EnergyAccessTimeseries />} />
            <Route path="access/raster" element={<EnergyAccessRaster />} />
            <Route path="access/raster/:name" element={<EnergyAccessRaster />} />
            <Route path="access/external" element={<EnergyAccessExternal />} />
            <Route path="consumption-demand/timeseries" element={<ConsDemTimeseries />} />
            <Route path="consumption-demand/raster" element={<ConsDemRaster />} />
            <Route path="consumption-demand/raster/:name" element={<ConsDemRaster />} />
            <Route path="consumption-demand/external" element={<ConsDemExternal />} />
            <Route path="infrastructure/timeseries" element={<EnergyInfrastructureTimeseries />} />
            <Route path="infrastructure/raster" element={<EnergyInfrastructureRaster />} />
            <Route path="infrastructure/raster/:name" element={<EnergyInfrastructureRaster />} />
            <Route path="infrastructure/external" element={<EnergyInfrastructureExternal />} />
            <Route path="models/timeseries" element={<ModelsTimeseries />} />
            <Route path="models/raster" element={<ModelsRaster />} />
            <Route path="models/raster/:name" element={<ModelsRaster />} />
            <Route path="models/external" element={<ModelsExternal />} />
            <Route path="projects/timeseries" element={<ProjectsTimeseries />} />
            <Route path="projects/raster" element={<ProjectsRaster />} />
            <Route path="projects/raster/:name" element={<ProjectsRaster />} />
            <Route path="projects/external" element={<ProjectsExternal />} />
            <Route path="sources/timeseries" element={<EnergySourceTimeseries />} />
            <Route path="sources/raster" element={<EnergySourceRaster />} />
            <Route path="sources/raster/:name" element={<EnergySourceRaster />} />
            <Route path="sources/external" element={<EnergySourceExternal />} />
            <Route path="*" element={<NotFound />} />
        </Routes>
    )
}

export default EnergyRoutes;