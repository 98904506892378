import React, {useState, useEffect} from "react";
import { useFormik } from "formik";
import { object, string, ref } from 'yup';
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

type formValues = {
    password: string;
    confirmPW: string;
    id: string;
}

const validationSchema = object().shape({
    password: string().min(8, 'Password must be at least 8 characters long').required('Password is required'),
    confirmPW: string().oneOf([ref('password')], 'Password does not match').required('Confirm the password')
});

export const Reset = () => {
    const navigate = useNavigate();
    const [error, setError] = useState<string | null>("")
    const [success, setSuccess] = useState<string | null>("")
    const [id, setId] = useState("")

    useEffect(() => {
        const token = localStorage.getItem('token')
        if (token){
            navigate('/sectors')
        } else {
            const url = window.location.href.split('/');
            setId(url[url.length - 1])
        }
    }, [])

    const formik = useFormik<formValues>({
        initialValues: {
            password: "",
            confirmPW: "",
            id: id
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            if (success){
                setSuccess(null);
            }

            if (error){
                setError(null)
            }
            values['id'] = id
            axios.post(`${process.env.REACT_APP_API_URL}/password-reset/`, values)
                .then(response => {
                    if (response.data['status'] === "success"){
                        setSuccess(response.data['message']);
                        navigate('/login', {state: {message: response.data['message']}})
                        return
                    }

                    if (response.data['status'] === "failure"){
                        setError(response.data['message']);
                        navigate('/sectors', {state: {message: response.data['message']}})
                        return
                    }
                })
                .catch(error => { 
                    alert('Oops, something went wrong')
                    return
                })
        },
    })

    function handlePasswordEyeToggle(nm: string) {
        const pwField: HTMLInputElement = document.querySelector(`#${nm}`)!;
        if (pwField.type === "password") {
            pwField.type = "text";
        } else {
            pwField.type = "password";
        }
    }

    return(
    <>
        <main className="d-flex justify-content-center align-items-center" style={{minHeight: "100vh"}} id="top">
            <section className="mt-5 mt-lg-0 bg-soft d-flex justify-content-center align-items-center">
                <div className="container">
                    <div className="row justify-content-center form-bg-image" data-background-lg="/static/assets/img/illustrations/signin.svg">
                        <div className="col-12 d-flex align-items-center justify-content-center">
                            <div className="bg-white shadow border-0 rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                                {success ? (<div className="alert alert-success text-center" role="alert">{success} <Link to="/login" className="fw-extrabold text-decoration-underline">Login</Link></div>) :
                                    error ? (<div className="alert alert-danger text-center" role="alert">{error}</div>) : null}
                                <div className="text-center text-md-center mb-4 mt-md-0">
                                    <h1 className="mb-0 h3">
                                        Reset your password
                                    </h1>
                                </div>
                                <form method="post" action="" className="mt-4" onSubmit={formik.handleSubmit}>
                                    <div className="form-group">
                                        <div className="form-group mb-2">
                                            <label htmlFor="password">Password</label>
                                            <div className="input-group">
                                                <span className="input-group-text" id="basic-addon2">
                                                    <span className="fas fa-unlock-alt">
                                                        <svg className="icon icon-xs text-gray-600" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M144 144v48H304V144c0-44.2-35.8-80-80-80s-80 35.8-80 80zM80 192V144C80 64.5 144.5 0 224 0s144 64.5 144 144v48h16c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V256c0-35.3 28.7-64 64-64H80z" /></svg>
                                                    </span>
                                                </span>
                                                <input
                                                    type="password"
                                                    name="password"
                                                    id="password"
                                                    placeholder="Enter Password"
                                                    className="form-control"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.password}
                                                />
                                                <div className="input-group-text" data-password="false" onClick={() => handlePasswordEyeToggle("password")}>
                                                    <Link to='#'>
                                                        <span className="password-eye"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye" viewBox="0 0 16 16"><path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"></path><path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"></path></svg></span>
                                                    </Link>
                                                </div>

                                            </div>
                                            {formik.touched.password && formik.errors.password ? <div className="alert alert-danger p-1 mt-1" role="alert">{formik.errors.password}</div> : null}
                                        </div>
                                        <div className="form-group mb-2">
                                            <label htmlFor="confirmPW">Confirm Password</label>
                                            <div className="input-group">
                                                <span className="input-group-text" id="basic-addon2">
                                                    <span className="fas fa-unlock-alt">
                                                        <svg className="icon icon-xs text-gray-600" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M144 144v48H304V144c0-44.2-35.8-80-80-80s-80 35.8-80 80zM80 192V144C80 64.5 144.5 0 224 0s144 64.5 144 144v48h16c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V256c0-35.3 28.7-64 64-64H80z" /></svg>
                                                    </span>
                                                </span>
                                                <input
                                                    type="password"
                                                    name="confirmPW"
                                                    id="confirmPW"
                                                    placeholder="Confirm Password"
                                                    className="form-control"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.confirmPW}
                                                />
                                                <div className="input-group-text" data-password="false" onClick={() => handlePasswordEyeToggle("confirmPW")}>
                                                    <Link to='#'>
                                                        <span className="password-eye"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye" viewBox="0 0 16 16"><path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"></path><path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"></path></svg></span>
                                                    </Link>
                                                </div>
                                            </div>
                                            {formik.touched.confirmPW && formik.errors.confirmPW ? <div className="alert alert-danger p-1 mt-1" role="alert">{formik.errors.confirmPW}</div> : null}
                                        </div>
                                    </div>
                                    <div className="d-grid">
                                        <button type="submit" name="reset" className="btn btn-gray-800">Reset</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    </>
    )
}