import React, { useEffect, useRef } from "react";
import { DataTypeSidebar } from "../../../../components/Sidebar/DataTypeSideBar";
import Navbar from "../../../../components/Navbar/Navbar";
import { TimeseriesLayout } from "../../../../components/TimeseriesRasterExternalLayout/TimeseriesLayout";
import Footer from "../../../../components/Footer/Footer";
import { IndustryVariables } from "../../../../utils/Timeseries/Variables/MacroEconomic/MacroEconomicTimeseriesVariables";
import { IndustryLocations } from "../../../../utils/Timeseries/Locations/MacroEconomic/IndustryLocations";
import { IndustryRasterVariables } from "../../../../utils/Raster/Variables/MacroEconomics/MacroEconomicsRasterVariables";

export const IndustryTimeseries = () => {
    const top = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (top.current) {
            top.current.scrollIntoView()
        }
    }, [])

    return (
        <main className="content px-0" ref={top}>
            <DataTypeSidebar
                timeseriesURL="/sectors/macro-economics/industry/timeseries"
                rasterURL="/sectors/macro-economics/industry/raster"
                externalURL="/sectors/macro-economics/industry/external"
                sectorName="Macro-Economics"
                type="timeseries"
                varList={IndustryRasterVariables}
            />
            <Navbar />
            <div className="row justify-content-center mb-5">
                <div className="col-12 text-center">
                    <h2 className="h1 px-lg-10">Macro-Economics Sector - Industry</h2>
                    <p className="lead px-lg-10">Timeseries Data for Macro-Economics on industry can be found here.</p>
                </div>
            </div>
            
            <TimeseriesLayout varList={IndustryVariables} locationList={IndustryLocations} />
            <Footer />
        </main>
    )
}