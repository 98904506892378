import React from "react";

export const WaterSources: {
    title: string
    dataSourceHeaderID: string
    dataSourceBodyID: string
    description: string
    dataPageURL: string
    sector: string
}[] = [
    {
        title: "Demand",
        dataSourceBodyID: "demand-body",
        dataSourceHeaderID: "demand-header",
        description: "Per capita demand (current & projected), sector demand (current & projected), environmental flows",
        dataPageURL: "/sectors/water/demand/raster",
        sector: "Water Sector"
    },
    {
        title: "Consumption",
        dataSourceBodyID: "water-consumption-body",
        dataSourceHeaderID: "water-consumption-header",
        description: "Per capita consumption, sector consumption, distribution efficiencies",
        dataPageURL: "/sectors/water/consumption/raster",
        sector: "Water Sector"
    },
    {
        title: "Access",
        dataSourceBodyID: "water-access-body",
        dataSourceHeaderID: "water-access-header",
        description: "Access to water and sanitation ",
        dataPageURL: "/sectors/water/access/raster",
        sector: "Water Sector"
    },
    {
        title: "Sources",
        dataSourceBodyID: "sources-body",
        dataSourceHeaderID: "sources-header",
        description: "Groundwater (Quantity & Quality), Surfacewater (Quantity & Quality) and Others (non-conventional, e.g. reuse, desalination, rainwater harvesting)",
        dataPageURL: "/sectors/water/sources/raster",
        sector: "Water Sector"
    },
    {
        title: "Watersheds",
        dataSourceBodyID: "watersheds-body",
        dataSourceHeaderID: "watersheds-header",
        description: "Catchment boundaries.",
        dataPageURL: "/sectors/water/watersheds/raster",
        sector: "Water Sector"
    },
    {
        title: "Aquifer Systems",
        dataSourceBodyID: "aquifer-systems-body",
        dataSourceHeaderID: "aquifer-systems-header",
        description: "Potential vegetation and soil carbon density (i.e., carbon density if the land grew to equilibrium) and a mature age. Vegetation carbon densities for crops from the FAO computed crop yield. All other carbon densities and mature ages come from Houghton (1999) and King (1997).",
        dataPageURL: "/sectors/water/aquifer-systems/raster",
        sector: "Water Sector"
    },
    {
        title: "Dams",
        dataSourceBodyID: "dams-body",
        dataSourceHeaderID: "dams-header",
        description: "Location, Use and Capacity of Dams.",
        dataPageURL: "/sectors/water/dams/raster",
        sector: "Water Sector"
    },
    {
        title: "Lakes",
        dataSourceBodyID: "lakes-body",
        dataSourceHeaderID: "lakes-header",
        description: "Location, Use and Capacity of Lakes.",
        dataPageURL: "/sectors/water/lakes/raster",
        sector: "Water Sector"
    },
    {
        title: "Ecosystems",
        dataSourceBodyID: "ecosystems-body",
        dataSourceHeaderID: "ecosystems-header",
        description: "Protected aquatic ecosystems (coastal, lakes, rivers, wetlands).",
        dataPageURL: "/sectors/water/ecosystems/raster",
        sector: "Water Sector"
    },
].sort((a, b) => a.title.localeCompare(b.title))