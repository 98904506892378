
export const EnergyAccessRasterVariables: string[] = []

export const EnergyConsumptionDemandRasterVariables: string[] = []

export const EnergyInfrastructureRasterVariables: string[] = [
    "Power Stations(sh)",
    "Transmission Network(sh)"
]

export const ModelsRasterVariables: string[] = []

export const ProjectsRasterVariables: string[] = []

export const EnergySourcesRasterVariables: string[] = []