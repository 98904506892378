import React from "react";

export const AgricSources: {
    title: string
    description: string
    dataPageURL: string
    sector: string
}[] = [
    {
        title: "Production",
        description: "Production of all crops and forestry products are contained in this data source.",
        dataPageURL: "/sectors/agriculture/production/timeseries",
        sector: "Agricultural / Land Sector"
    },
    {
        title: "Consumption",
        description: "Food, non-food, bioenergy, and feed consumption of all crop and forestry products to complement FAO data.",
        dataPageURL: "/sectors/agriculture/consumption/timeseries",
        sector: "Agricultural / Land Sector"
    },
    {
        title: "Prices",
        description: "Prices of all food, feed, and forestry commodities. Compliment producer prices from FAO.",
        dataPageURL: "/sectors/agriculture/prices/timeseries",
        sector: "Agricultural / Land Sector"
    },
    {
        title: "Land",
        description: "Land cover and land use, NDVI. This can compliment information from 1700 for the carbon cycle within GCAM. A blend of the Hurtt-Hyde land cover product from CMIP5, the SAGE potential vegetation map, and FAO/GTAP cropland area.",
        dataPageURL: "/sectors/agriculture/land/timeseries",
        sector: "Agricultural / Land Sector"
    },
    {
        title: "Carbon",
        description: "Potential vegetation and soil carbon density (i.e., carbon density if the land grew to equilibrium) and a mature age. Vegetation carbon densities for crops from the FAO computed crop yield. All other carbon densities and mature ages come from Houghton (1999) and King (1997).",
        dataPageURL: "/sectors/agriculture/carbon/timeseries",
        sector: "Agricultural / Land Sector"
    },
    {
        title: "Soil",
        description: "This data source contains data relating to soil type, composition, Map etc.",
        dataPageURL: "/sectors/agriculture/soil/timeseries",
        sector: "Agricultural / Land Sector"
    },
    {
        title: "Other-Emissions",
        description: "Emissions of all non-CO2 gases for sub-wards, wards, counties,countries. Data for BC and OC is from the RCP inventory (Lamarque et al., 2011). Data for all other gases and species is from EDGAR (European Commission, 2010).",
        dataPageURL: "/sectors/agriculture/other-emissions/timeseries",
        sector: "Agricultural / Land Sector"
    }
].sort((a, b) => a.title.localeCompare(b.title))