import React from "react";

export const GDP_GRVariables: string[] = []

export const GDPVariables: string[] = []

export const IncomeVariables: string[] = []

export const IndustryVariables: string[] = []

export const InfrastructureVariables: string[] = []

export const OccupationVariables: string[] = []

export const PopulationVariables: string[] = []
