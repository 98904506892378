import React from "react";
export const WeatherObservationLocations: string[] = [
    "Bangale",
    "Chewani",
    "Chewele",
    "Garsen Central",
    "Garsen North",
    "Garsen South",
    "Garsen West",
    "Hirimani",
    "Kinakomba",
    "Kipini East",
    "Kipini West",
    "Madogo",
    "Mikinduni",
    "Sala",
    "Wayu"
]