import React from 'react';
export const LakesLocations: string[] = [
    "Daboya",
    "Nakong",
    "Nangodi",
    "Nasia",
    "Pwalugu",
    "Wiase",
    "Yagaba",
    "Yarugu"
]