import React from "react";

export const EnergyAccessVariables: string[] = []

export const EnergyConsumptionDemandVariables: string[] = []

export const EnergyInfrastructureVariables: string[] = []

export const ModelsVariables: string[] = []

export const ProjectsVariables: string[] = []

export const EnergySourcesVariables: string[] = []
