import React, { useEffect, useRef } from "react";
import { DataTypeSidebar } from "../../../../components/Sidebar/DataTypeSideBar";
import Navbar from "../../../../components/Navbar/Navbar";
import Footer from "../../../../components/Footer/Footer";
import { ExternalLayout } from "../../../../components/TimeseriesRasterExternalLayout/ExternalLayout";
import { DamsUrls } from "../../../../utils/External/urls/Water/WaterExternalURLS";
import { DamsRasterVariables } from "../../../../utils/Raster/Variables/Water/WaterRasterVariables";

export const DamsExternal = () => {
    const top = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (top.current) {
            top.current.scrollIntoView()
        }
    }, [])

    return (
        <main className="content px-0" ref={top}>
            <DataTypeSidebar
                timeseriesURL="/sectors/water/dams/timeseries"
                rasterURL="/sectors/water/dams/raster"
                externalURL="/sectors/water/dams/external"
                sectorName="Water"
                type="external"
                varList={DamsRasterVariables}
            />
            <Navbar />
            <div className="row justify-content-center mb-5">
                <div className="col-12 text-center">
                    <h2 className="h1 px-lg-10">Water Sector - Access</h2>
                    <p className="lead px-lg-10">Get all external data relating to dams in the water sector by visiting the link(s) listed below.</p>
                </div>
            </div>
            <div className="card card-body shadow border-0 mx-3">
                <ExternalLayout urlList={DamsUrls} />
            </div>
            <Footer />
        </main>
    )
}