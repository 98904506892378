export const CarbonRasterVariables: string[] = []

export const ConsumptionRasterVariables: string[] = []

export const LandRasterVariables: string[] = [
    "Volta Cropping System(sh)",
    "Kenya Bare Areas(sh)",
    "Kenya Coral Reefs(sh)",
    "Kenya Flood Plains(sh)",
    "Kenya Forests(sh)",
    "Kenya Mangroves(sh)",
    "Kenya Mangroves UNDP-line(sh)",
    "Kenya Protected Areas(sh)",
    "Kenya Rangeland(sh)",
    "Kenya Sand Beaches(sh)",
    "Kenya Urban(sh)",
    "Kenya Wetlands(sh)"
]

export const OtherEmissionsRasterVariables: string[] = [
    "Geology(sh)"
]

export const PricesRasterVariables: string[] = []

export const ProductionRasterVariables: string[] = []

export const SoilRasterVariables: string[] = [
    "Soils(sh)"
]