import React, { useEffect, useRef} from "react";
import { DataTypeSidebar } from "../../../../components/Sidebar/DataTypeSideBar";
import Navbar from "../../../../components/Navbar/Navbar";
import Footer from "../../../../components/Footer/Footer";
import { OtherEmissionsVariables } from "../../../../utils/Timeseries/Variables/Agriculture/AgricultureTimeseriesVariables";
import { TimeseriesLayout } from "../../../../components/TimeseriesRasterExternalLayout/TimeseriesLayout";
import { OtherEmissionsLocations } from "../../../../utils/Timeseries/Locations/Agriculture/OtherEmissionsLocations";
import { OtherEmissionsRasterVariables } from "../../../../utils/Raster/Variables/Agriculture/AgriculturalRasterVariables";

export const OtherEmissionsTimeseries = () => {
    const top = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (top.current) {
            top.current.scrollIntoView()
        }
    }, [])

    return (
        <main className="content px-0" ref={top}>
            <DataTypeSidebar
                timeseriesURL="/sectors/agriculture/other-emissions/timeseries"
                rasterURL="/sectors/agriculture/other-emissions/raster"
                externalURL="/sectors/agriculture/other-emissions/external"
                sectorName="Agricultural / Land"
                type="timeseries"
                varList={OtherEmissionsRasterVariables}
            />
            <Navbar />
            <div className="row justify-content-center mb-5">
                <div className="col-12 text-center">
                    <h2 className="h1 px-lg-9">Agricultural / Land Sector - Other Emissions</h2>
                    <p className="lead px-lg-9">Timeseries Data for agricultural / land sector on other-emissions of all non-CO2 gases for sub-wards, wards, counties,countries can be found here.</p>
                </div>
            </div>
            
            <TimeseriesLayout varList={OtherEmissionsVariables} locationList={OtherEmissionsLocations} />
            <Footer />
        </main>
    )
}