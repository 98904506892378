import React from "react";

interface ListProps {
    name: string
    handleVarSelection?: (varName: string) => void
}

export const Locations = ({ name, handleVarSelection }: ListProps) => {
    return (
        <li className="hover-state p-1 ps-2 list-group-item d-flex align-items-center">
            <div>
                <div className="form-check form-switch">
                    {handleVarSelection? (
                        <input className="form-check-input locName" type="checkbox" id={name.replaceAll(" ", "-").toLowerCase()} name={name} onChange={() => handleVarSelection(name.replaceAll(" ", "-").toLowerCase())} />
                    ) : (
                        <input className="form-check-input locName" type="checkbox" id={name.replaceAll(" ", "-").toLowerCase()} name={name} />
                    )}
                </div>
            </div>
            <div>
                <label className="form-check-label m-0 cursor" htmlFor={name.replaceAll(" ", "-").toLowerCase()}>{name}</label>
            </div>
        </li>
    )
}