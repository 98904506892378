import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import './Sidebar.css'
import axios from "axios";

export const ClimateSidebar = () => {
    const [user, setUser] = useState("");
    const [login, setLogin] = useState(false)

    useEffect(() => {
        const username = localStorage.getItem('username')
        if (username) {
            setLogin(true);
            if (username) {
                setUser(username.split(' ')[0]);
            }
        }
    }, [])

    const logout = () => {
        localStorage.clear()
    }

    return (
        <>
            <nav className="navbar navbar-dark navbar-theme-primary px-4 col-12 d-lg-none">
                <a className="navbar-brand me-lg-5" href="/">
                    <img className="navbar-brand-dark" src="/assets/images/logoWhite.png" alt="EPIC logo" />
                    <img className="navbar-brand-light" src="/assets/images/logoWhite.png" alt="EPIC logo" />
                </a>
                <div className="d-flex align-items-center">
                    <button className="navbar-toggler d-lg-none collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                    </button>
                </div>
            </nav>
            <nav id="sidebarMenu" className="sidebar d-lg-block bg-gray-800 text-white collapse" data-simplebar>
                <div className="sidebar-inner px-2 pt-1">
                <div className="user-card">
                        <div className="mt-5 d-flex align-items-center justify-content-between justify-content-md-center pb-2">
                            <div className="d-flex align-items-center">
                                <Link to="/" className="nav-link d-flex align-items-center">
                                    <span className="sidebar-icon m-0">
                                        <img src="/assets/images/logoWhite.png" alt="EPIC logo" height="50" width="50" />
                                    </span>
                                </Link>
                                {login? (<div className="d-block d-lg-none">
                                            <h2 className="h4 mb-2">Hi, {user}</h2>
                                            <a className="p-0 fs-6 dropdown-item d-flex align-items-center" href={window.location.href} onClick={() => { logout() }}>
                                                <svg className="dropdown-icon text-danger me-0" height="20" width="20" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"></path></svg>
                                                Logout
                                            </a>
                                        </div>) : <h2 style={{marginBottom: '0'}}>CLEWS!</h2>}
                            </div>
                            <div className="collapse-close d-md-none" style={{border: '1px solid white'}}>
                                <a href="#sidebarMenu" data-bs-toggle="collapse" className="d-flex"
                                    data-bs-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="true"
                                    aria-label="Toggle navigation">
                                    <svg className="icon icon-xs" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                </a>
                            </div>
                        </div>
                        <ul className="nav flex-column pb-3 pt-md-0 d-lg-none">
                            <li className="nav-item">
                                <Link to="/about" className="nav-link d-flex align-items-center">
                                    About &nbsp;<svg xmlns="http://www.w3.org/2000/svg" height="16" width="8" viewBox="0 0 256 512"><path fill="white" d="M246.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-9.2-9.2-22.9-11.9-34.9-6.9s-19.8 16.6-19.8 29.6l0 256c0 12.9 7.8 24.6 19.8 29.6s25.7 2.2 34.9-6.9l128-128z"/></svg>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/partners" className="nav-link d-flex align-items-center">
                                    Partners &nbsp;<svg xmlns="http://www.w3.org/2000/svg" height="16" width="8" viewBox="0 0 256 512"><path fill="white" d="M246.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-9.2-9.2-22.9-11.9-34.9-6.9s-19.8 16.6-19.8 29.6l0 256c0 12.9 7.8 24.6 19.8 29.6s25.7 2.2 34.9-6.9l128-128z"/></svg>
                                </Link>
                            </li>
                            {!login? (
                                <li className="nav-item">
                                    <Link to="/login" className="nav-link d-flex align-items-center">
                                        Login &nbsp;<svg xmlns="http://www.w3.org/2000/svg" height="16" width="8" viewBox="0 0 256 512"><path fill="white" d="M246.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-9.2-9.2-22.9-11.9-34.9-6.9s-19.8 16.6-19.8 29.6l0 256c0 12.9 7.8 24.6 19.8 29.6s25.7 2.2 34.9-6.9l128-128z"/></svg>
                                    </Link>
                                </li>
                            ) : (null)}
                        </ul>
                    </div>
                    <ul className="nav flex-column pt-md-0">
                        <li className="nav-item mb-2">
                            <Link to="/sectors">
                                <div className="m-3 text-secondary">
                                    <svg className="icon icon-xs me-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M48 256a208 208 0 1 1 416 0A208 208 0 1 1 48 256zm464 0A256 256 0 1 0 0 256a256 256 0 1 0 512 0zM217.4 376.9c4.2 4.5 10.1 7.1 16.3 7.1c12.3 0 22.3-10 22.3-22.3V304h96c17.7 0 32-14.3 32-32V240c0-17.7-14.3-32-32-32H256V150.3c0-12.3-10-22.3-22.3-22.3c-6.2 0-12.1 2.6-16.3 7.1L117.5 242.2c-3.5 3.8-5.5 8.7-5.5 13.8s2 10.1 5.5 13.8l99.9 107.1z" /></svg>
                                    Go back to Sectors
                                </div>
                            </Link>
                        </li>
                        <li className="nav-item mb-2 active" id="production">
                            <Link to="/sectors/climate" className="nav-link">
                                <span className="sidebar-icon me-0">
                                    <svg className="icon icon-xs me-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z"></path><path d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z"></path></svg>
                                </span>
                                <span className="sidebar-text">Climate Sector</span>
                            </Link>
                        </li>
                    </ul>
                </div>
            </nav>
        </>
    );
};
