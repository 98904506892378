import React from 'react'
import Navbar from '../../components/Navbar/Navbar';
import './About.css'
import Footer from '../../components/Footer/Footer';
import CustomSidebar from '../../components/Sidebar/About&Partners';

const About = () => {
    return (
        <>
            <CustomSidebar />
            <Navbar />
            <section style={{padding: '0'}} className="section-header overflow-hidden text-white landing d-flex justify-content-center align-items-center" id="aboutPage">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center">
                            <h1 className="text-secondary fw-extrabold display-1">ABOUT US</h1>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className='row me-1 ms-1 mt-4'>
                    <div className='col-12 col-xl-7'>
                        <div className='shadow'>
                            <div className="card border-0 shadow">
                                <div className="card-body">
                                    <div className="row d-block d-xl-flex align-items-center">
                                        <div className="px-xl-0">
                                            <div>
                                                <p className="text-primary display-6">Welcome to the CLEWs Data Observatory, your gateway to data excellence. We're committed to facilitating your journey in the world of data-driven decision-making.</p>
                                                <p>
                                                    The goal is to provide a comprehensive repository of data, meticulously organized and FAIR-compliant, designed for ease of use for reasearch and other pruposes. With a focus on the Water-Energy-Food-Environment Nexus, we bring together datasets, version control, endorsements, and a robust IT infrastructure to empower your decision-making processes.
                                                    Our collaborative efforts connect you to the Global Earth Observation System of Systems (GEOSS), ensuring that you have access to a world of data for research and informed choices.
                                                </p>
                                                <p>We're dedicated to supporting your work through the EPIC Africa Research Network.
                                                    With CLEWs Data Observatory portal, it is not just a platform; it is your partner in the data-driven future. Join us in exploring the vast landscape of data and make it work for you.
                                                    Your data, your insights, your decisions - all in one place.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-12 col-xl-4'>
                        <img src="assets/images/diagram.jpg" alt='CLEWS Diagram' />
                    </div>
                </div>
            </section>
            <div className="px-2">
                <Footer />
            </div>
        </>
    )
}

export default About;