import React, { useState, useEffect } from "react";
import { ExportButton } from "../ExportButton/ExportButton";
import { VariableList } from "../VariableList/VariableList";
import { MapContainer, TileLayer, WMSTileLayer } from "react-leaflet";
import "./TR_Custom.css"

interface RasterProps {
    varList: (string | null)[]
    description?: string
    attribution?: string
}

export const RasterLayout = ({ varList=[], description, attribution }: RasterProps) => {
    const [inputElements, setInputElements] = useState<Array<HTMLInputElement>>([]);
    const [lastEle, setLastEle] = useState<string>();

    useEffect(() => {
        const url = window.location.href.split('/');
        if (url[url.length - 1] === ""){
            setLastEle(url[url.length - 2])
        }else{
            setLastEle(url[url.length - 1])
        }
            
    }, [inputElements])

    const handleVarSelection = (name: string) => {
        const variable = document.getElementById(name)! as HTMLInputElement;
        if (variable.checked){setInputElements([...inputElements, variable])}
        else {
            const temp = [...inputElements]
            temp.slice(temp.indexOf(variable), 1)
            setInputElements(temp)
        }
    }

    return (
        <>
            <div className="col-12 px-2 d-flex justify-content-center" id="alert-noti">
                <div className="col-12 mb-4">
                    <div className="card notification-card border-0 shadow">
                        <div className="card-header d-flex align-items-center">
                            <div className="col-md-4 pe-3">
                                <h2 className="fs-5 fw-bold mb-0">Raster Data</h2>
                            </div>
                            <div className="col-md-8 ps-1">
                                <ExportButton theType={'raster'} layers={inputElements
                                        .filter((item, index) => item.checked && inputElements.indexOf(item) === index)
                                        .map((varr) => {
                                            return(varr.name)})}/>
                            </div>
                        </div>
                        <div className="row card-body">
                            <div className="col-md-4 pt-1">
                                <h6 className="mb-0 fw-bolder">Select Layer(s)</h6>
                            </div>
                            <div className="p-0 col-md-8 overflow-auto" style={{maxHeight: "10rem", scrollbarWidth: "thin"}}>
                                {lastEle === 'raster' && varList.length < 1 ? <p>No data source selected</p> : lastEle === 'raster' && varList.length > 0 ? (
                                    <p className="ps-3">No data source selected</p>
                                ) : (
                                <form method="post">
                                    <ul className="list-group list-group-flush">
                                        {varList && varList.map((variable, index) => {
                                            return (
                                                <VariableList key={index + variable!} name={variable!} handleVarSelection={handleVarSelection} />
                                            )
                                        })}
                                    </ul>
                                </form>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* visualizing */}
            <div className="col-12">

                {inputElements.length === 0 ? (<div className="row">
                    <div className="col-12">
                        <MapContainer
                            center={[9.1021, 18.2812]}
                            zoom={4}
                            scrollWheelZoom={false}
                        >
                            <TileLayer
                                attribution='&copy;
                                <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                        </MapContainer>
                    </div>
                </div>) : (
                    <div className="card card-body shadow border-0 p-2 mb-3">
                        <div className="row">
                            <div className="col-12">
                                <MapContainer
                                    center={[9.1021, 18.2812]}
                                    zoom={4}
                                    scrollWheelZoom={true}
                                >
                                    <TileLayer
                                        attribution='&copy;
                                            <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                    />
                                    {inputElements
                                        .filter((item, index) => item.checked && inputElements.indexOf(item) === index)
                                        .map((varr, index) => {
                                            return(
                                                <WMSTileLayer
                                                    key={index + varr.name}
                                                    opacity={1}
                                                    transparent
                                                    layers={`${lastEle!.replaceAll('_', ' ')}:${varr.name}`}
                                                    format="image/png"
                                                    url={`${process.env.REACT_APP_GEOSERVER_URL!}/${process.env.REACT_APP_WORKSPACE_NAME}/wms?`}
                                                    version="1.1.0"
                                                    attribution={"attribution"}
                                                />
                                        )})
                                    }
                                </MapContainer>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    )
}
