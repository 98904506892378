import React from "react";

export const EnergyAccessUrls: string[][] = [
    ['Electricity access (national, rural, urban)', 'https://www.foodsystemsdashboard.org/countries/ken/subnational-data/indicators/admin-1', 'Food Systems Dashboard'],
    ['World Bank data source based on nationality(Rural and Urban)', 'https://data.worldbank.org/indicator/EG.ELC.ACCS.RU.ZS?locations=KE', 'World Bank'],
    ["Embu, Garissa, Isiolo, Kirinyago, Kitui, Machakos, Meru, Murang'a, Nyeri, Tana-River (Basin), Tharaka-Nithi", 'https://www.foodsystemsdashboard.org/countries/ken/subnational-data/indicators/admin-1/overall-poverty/table', 'Food Systems Dashboard'],
    ["Embu, Garissa, Isiolo, Kirinyago, Kitui, Machakos, Meru, Murang'a, Nyeri, Tana-River (Basin), Tharaka-Nithi", 'https://statistics.kilimo.go.ke/', 'Ministry of Agriculture and Livestock Development']
]

export const EnergyConsumptionDemandUrls: string[][] = [
    ["Energy Consumption and demand", "https://au-afrec.org/sites/default/files/2022-05/afrec-africa-energy-balance-2019-en.pdf", "afrec-africa-energy-balance-2019-en.pdf"]
]

export const EnergyInfrastructureUrls: string[][] = [
    ["Infrastructure", "https://www.epra.go.ke/wp-content/uploads/2021/03/Energy-and-Petroleum-Statistics-Report-2020.pdf", "Energy-and-Petroleum-Statistics-Report-2020.pdf"]
]

export const ModelsUrls: string[][] = [
    ["Main Grid and Off-Grid energy source", "https://au-afrec.org/sites/default/files/2022-05/afrec-africa-energy-balance-2019-en.pdf", "afrec-africa-energy-balance-2019-en.pdf"],
    ["Biofuels and Fossil Fuels", "https://www.epra.go.ke/wp-content/uploads/2021/03/Energy-and-Petroleum-Statistics-Report-2020.pdf", "Energy-and-Petroleum-Statistics-Report-2020.pdf"]
]

export const ProjectsUrls: string[][] = []

export const EnergySourcesUrls: string[][] = [
    ["Main Grid and Off-Grid energy source", "https://au-afrec.org/sites/default/files/2022-05/afrec-africa-energy-balance-2019-en.pdf", "afrec-africa-energy-balance-2019-en.pdf"],
    ["Biofuels and Fossil Fuels", "https://www.epra.go.ke/wp-content/uploads/2021/03/Energy-and-Petroleum-Statistics-Report-2020.pdf", "Energy-and-Petroleum-Statistics-Report-2020.pdf"]
]
