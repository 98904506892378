import React, { useEffect, useRef } from "react";
import { DataTypeSidebar } from "../../../../components/Sidebar/DataTypeSideBar";
import Navbar from "../../../../components/Navbar/Navbar";
import Footer from "../../../../components/Footer/Footer";
import { ExternalLayout } from "../../../../components/TimeseriesRasterExternalLayout/ExternalLayout";
import { IncomeUrls } from "../../../../utils/External/urls/MacroEconomic/MacroEconomicExternaURLS";
import { IncomeRasterVariables } from "../../../../utils/Raster/Variables/MacroEconomics/MacroEconomicsRasterVariables";

export const IncomeExternal = () => {
    const top = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (top.current) {
            top.current.scrollIntoView()
        }
    }, [])

    return (
        <main className="content px-0" ref={top}>
            <DataTypeSidebar
                timeseriesURL="/sectors/macro-economics/income/timeseries"
                rasterURL="/sectors/macro-economics/income/raster"
                externalURL="/sectors/macro-economics/income/external"
                sectorName="Macro-Economics"
                type="external"
                varList={IncomeRasterVariables}
            />
            <Navbar />
            <div className="row justify-content-center mb-5">
                <div className="col-12 text-center">
                    <h2 className="h1 px-lg-10">Macro-Economics Sector - Income</h2>
                    <p className="lead px-lg-10">Get all external data relating to income in the macro-economics sector by visiting the link(s) listed below.</p>
                </div>
            </div>
            <div className="card card-body shadow border-0 mx-3">
                <ExternalLayout urlList={IncomeUrls} />
            </div>
            <Footer />
        </main>
    )
}