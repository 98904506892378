import React from "react";
import { Routes, Route } from 'react-router-dom';
import { OverviewTimeseries } from "../../../pages/FilterBy/Timeseries/TimeseriesFilterOverview";
import NotFound from "../../../pages/404Page/404page";
import { ProductionTimeseries } from "../../../pages/Sectors/Agriculture/Production/ProductionTimeseries";
import { ConsumptionTimeseries } from "../../../pages/Sectors/Agriculture/Consumption/ConsumptionTimeseries";
import { PricesTimeseries } from "../../../pages/Sectors/Agriculture/Prices/PricesTimeseries";
import { LandTimeseries } from "../../../pages/Sectors/Agriculture/Land/LandTimeseries";
import { CarbonTimeseries } from "../../../pages/Sectors/Agriculture/Carbon/CarbonTimeseseries";
import { SoilTimeseries } from "../../../pages/Sectors/Agriculture/Soil/SoilTimeseries";
import { OtherEmissionsTimeseries } from "../../../pages/Sectors/Agriculture/OtherEmissions/OtherEmissionsTimeseries";
import { WaterAccessTimeseries } from "../../../pages/Sectors/Water/Access/WaterAccessTimeseries";
import { AquiferSystemsTimeseries } from "../../../pages/Sectors/Water/AquiferSytems/AquiferSystemsTimeseries";
import { WaterConsumptionTimeseries } from "../../../pages/Sectors/Water/Consumption/WaterConsumptionTimeseries";
import { DamsTimeseries } from "../../../pages/Sectors/Water/Dams/DamsTimeseries";
import { DemandTimeseries } from "../../../pages/Sectors/Water/Demand/DemandTimeseries";
import { EcosystemTimeseries } from "../../../pages/Sectors/Water/Ecosystem/EcosystemTimeseries";
import { LakesTimeseries } from "../../../pages/Sectors/Water/Lakes/LakesTimeseries";
import { WaterSourcesTimeseries } from "../../../pages/Sectors/Water/Sources/WaterSourcesTimeseries";
import { WatershedsTimeseries } from "../../../pages/Sectors/Water/Watersheds/WatershedsTimeseries";
import { EnergyAccessTimeseries } from "../../../pages/Sectors/Energy/Access/EnergyAccessTimeseries";
import { ConsDemTimeseries } from "../../../pages/Sectors/Energy/ConsumptionDemand/EnergyConsDemTimeseries";
import { EnergyInfrastructureTimeseries } from "../../../pages/Sectors/Energy/Infrastructure/EnergyInfrastructureTimeseries";
import { ModelsTimeseries } from "../../../pages/Sectors/Energy/Models/ModelsTimeseries";
import { ProjectsTimeseries } from "../../../pages/Sectors/Energy/Projects/ProjectsTimeseries";
import { EnergySourceTimeseries } from "../../../pages/Sectors/Energy/Sources/EnergySourcesTimeseries";
import { GDPTimeseries } from "../../../pages/Sectors/MacroEconomics/GDP/GDPTimeseries";
import { GDP_GRTimeseries } from "../../../pages/Sectors/MacroEconomics/GDP_GR/GDP_GRTimeseries";
import { IncomeTimeseries } from "../../../pages/Sectors/MacroEconomics/Income/IncomeTimeseries";
import { IndustryTimeseries } from "../../../pages/Sectors/MacroEconomics/Industry/IndustryTimeseries";
import { InfrastructureTimeseries } from "../../../pages/Sectors/MacroEconomics/Infrastructure/InfrastructureTimeseries";
import { OccupationTimeseries } from "../../../pages/Sectors/MacroEconomics/Occupation/OccupationTimeseries";
import { PopulationTimeseries } from "../../../pages/Sectors/MacroEconomics/Population/PopulationTimeseries";
import { ClimateProjectionTimeseries } from "../../../pages/Sectors/Climate/ClimateProjection/ClimateProjectionTimeseries";
import { WeatherForecastTimeseries } from "../../../pages/Sectors/Climate/WeatherForecast/WeatherForecastTimeseries";
import { WeatherObservationTimeseries } from "../../../pages/Sectors/Climate/WeatherObservation/WeatherObservationTimeseries";

export const FilterTimeseriesRoutes = () => {
    return (
        <Routes>
            <Route path="overview" element={<OverviewTimeseries />} />
            <Route path="agriculture/production/timeseries" element={<ProductionTimeseries />} />
            <Route path="agriculture/consumption/timeseries" element={<ConsumptionTimeseries />} />
            <Route path="agriculture/prices/timeseries" element={<PricesTimeseries />} />
            <Route path="agriculture/land/timeseries" element={<LandTimeseries />} />
            <Route path="agriculture/carbon/timeseries" element={<CarbonTimeseries />} />
            <Route path="agriculture/soil/timeseries" element={<SoilTimeseries />} />
            <Route path="agriculture/other-emissions/timeseries" element={<OtherEmissionsTimeseries />} />
            <Route path="water/access/timeseries" element={<WaterAccessTimeseries />} />
            <Route path="water/aquifer-systems/timeseries" element={<AquiferSystemsTimeseries />} />
            <Route path="water/consumption/timeseries" element={<WaterConsumptionTimeseries />} />
            <Route path="water/dams/timeseries" element={<DamsTimeseries />} />
            <Route path="water/demand/timeseries" element={<DemandTimeseries />} />
            <Route path="water/ecosystems/timeseries" element={<EcosystemTimeseries />} />
            <Route path="water/lakes/timeseries" element={<LakesTimeseries />} />
            <Route path="water/sources/timeseries" element={<WaterSourcesTimeseries />} />
            <Route path="water/watersheds/timeseries" element={<WatershedsTimeseries />} />
            <Route path="energy/access/timeseries" element={<EnergyAccessTimeseries />} />
            <Route path="energy/consumption-demand/timeseries" element={<ConsDemTimeseries />} />
            <Route path="energy/infrastructure/timeseries" element={<EnergyInfrastructureTimeseries />} />
            <Route path="energy/models/timeseries" element={<ModelsTimeseries />} />
            <Route path="energy/projects/timeseries" element={<ProjectsTimeseries />} />
            <Route path="energy/sources/timeseries" element={<EnergySourceTimeseries />} />
            <Route path="macro-economics/gdp/timeseries" element={<GDPTimeseries />} />
            <Route path="macro-economics/gdp-growth-rate/timeseries" element={<GDP_GRTimeseries />} />
            <Route path="macro-economics/income/timeseries" element={<IncomeTimeseries />} />
            <Route path="macro-economics/industry/timeseries" element={<IndustryTimeseries />} />
            <Route path="macro-economics/infrastructure/timeseries" element={<InfrastructureTimeseries />} />
            <Route path="macro-economics/occupation/timeseries" element={<OccupationTimeseries />} />
            <Route path="macro-economics/population/timeseries" element={<PopulationTimeseries />} />
            <Route path="climate/climate-projections/timeseries" element={<ClimateProjectionTimeseries />} />
            <Route path="climate/weather-forecasts/timeseries" element={<WeatherForecastTimeseries />} />
            <Route path="climate/weather-observations/timeseries" element={<WeatherObservationTimeseries />} />
            <Route path="*" element={<NotFound />} />
        </Routes>
    )
}