import React, {useRef, useEffect} from 'react';
import Navbar from '../../components/Navbar/Navbar';
import './Sectors.css'
import Sidebar from '../../components/Sidebar/Sidebar';
import Footer from '../../components/Footer/Footer';
import ExploreLink from './ExploreLink';
import { useLocation } from 'react-router-dom';


const Sectors = () => {
    const top = useRef<HTMLDivElement>(null)
    const location = useLocation();

    useEffect(() => {
        window.history.replaceState({}, '')
        if (top.current) {
            top.current.scrollIntoView()
        }
    }, [])

    function clear(){
        window.history.replaceState({}, '')
    }

    return (
        <>
            <main className="content" style={{padding: '0'}} ref={top}>
                <Sidebar />
                <Navbar />
                <div id='Hero' className='py-lg fs-1 fw-extrabold text-center mx-2 mt-2'> CLEWS Data Observatory </div>
                <div className="container mt-4">
                    {location.state && <div className="alert alert-info alert-dismissible fade show" role="alert">
                        <strong>{location.state.message}</strong>
                        <button onClick={() => clear()} type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                        </div>}
                    <div className='row mb-5 justify-content-center'>
                        <div className="col-12 col-sm-4 mb-4">
                            <div className="card shadow border-0 p-0">
                                <div className="profile-cover rounded-top agric"></div>
                                <div className="card-body pb-3">
                                    <h4 className="h3 text-center fw-extrabold">
                                        Agriculture/Land
                                    </h4>
                                    <p className="text-gray mb-1 text-center">
                                        Find all related data about agriculture in this repository.
                                    </p>
                                </div>
                                <ExploreLink link="/sectors/agriculture" />
                            </div>
                        </div>

                        <div className="col-12 col-sm-4 mb-4">
                            <div className="card shadow border-0 p-0">
                                <div className="profile-cover rounded-top water"></div>
                                <div className="card-body pb-3">
                                    <h4 className="h3 text-center fw-extrabold">
                                        Water
                                    </h4>
                                    <p className="text-gray mb-1 text-center">
                                        This repository contains all water resource-related data.
                                    </p>
                                </div>
                                <ExploreLink link="/sectors/water" />
                            </div>
                        </div>

                        <div className="col-12 col-sm-4 mb-4">
                            <div className="card shadow border-0 p-0">
                                <div className="profile-cover rounded-top energy"></div>
                                <div className="card-body pb-3">
                                    <h4 className="h3 text-center fw-extrabold">
                                        Energy
                                    </h4>
                                    <p className="text-gray mb-1 text-center">
                                        In this repository, you will discover data pertaining to energy resources.
                                    </p>
                                </div>
                                <ExploreLink link="/sectors/energy" />
                            </div>
                        </div>

                        <div className="col-12 col-sm-4 mb-4">
                            <div className="card shadow border-0 p-0">
                                <div className="profile-cover rounded-top socio-economic-politico"></div>
                                <div className="card-body pb-3">
                                    <h4 className="h3 text-center fw-extrabold">
                                        Macro-Economics
                                    </h4>
                                    <p className="text-gray mb-1 text-center">
                                        In this repository, you will discover data pertaining to energy resources that you can utilize.
                                    </p>
                                </div>
                                <ExploreLink link="/sectors/macro-economics" />
                            </div>
                        </div>
                        
                        <div className="col-12 col-sm-4 mb-4">
                            <div className="card shadow border-0 p-0">
                                <div className="profile-cover rounded-top climate"></div>
                                <div className="card-body pb-3">
                                    <h4 className="h3 text-center fw-extrabold">
                                        Climate
                                    </h4>
                                    <p className="text-gray mb-1 text-center">
                                        This repository contains information about climate resources that you can make use of.
                                    </p>
                                </div>
                                <ExploreLink link="/sectors/climate" />
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </main>
        </>
    )
}

export default Sectors;