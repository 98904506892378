import React from 'react'


const Footer = () => {
    const classes: string = "bg-gray-800 text-white rounded shadow p-3 mb-2 mt-2 mx-2"

    return (
        <>
            <footer className={classes}>
                <div className="container">
                    <div className='row'>
                        <div className="col-12 col-md-4 col-xl-6 mb-4 mb-md-0 d-flex justify-content-center align-content-center">
                            <img className="navbar-brand-dark mb-4 rounded avatar-xl" height="35" src="/assets/images/european-union-logo-vector.svg" alt="European Union Logo" />
                            <span className="h5 mb-3 d-block ">
                                <p className='mt-4'>This project is funded by the Horizon Europe Framework Programme (HORIZON) under grant agreement No.101083763.</p>
                            </span>
                        </div>
                        <div className="col-12 col-md-8 col-xl-6 text-center text-lg-start">
                            <span className="h5 mb-3 d-block text-mute">
                                <p>The CLEWS Data Observatory</p>
                            </span>
                            <p>For more imformation about the EPIC Africa Project, visit <a href='https://www.epicafrica.eu/' target='_blank' rel="noopener noreferrer" className='text-info'>www.epicafrica.eu</a></p>
                            <div>© All Rights Reserved</div>
                        </div>

                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer;