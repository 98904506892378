import React from "react";
import { Link } from "react-router-dom";
import "./DataRepresentation.css"

interface datarepProps {
    visualizeURL: string
    rawdataURL: string
    externalURL: string
}

const DataRepresentation = (props: datarepProps) => {
    return (
        <>
            <section className="section section-sm pt-0 mt-3">
                <div className="container">
                    <div className="row justify-content-center mb-5">
                        <div className="col-12 text-center">
                            <p className="lead px-lg-10">Choose a preferred representation of data.</p>
                            <p className="lead px-lg-10">I. Visualised Data  II. Timeseries Data  III. External Data</p>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-3 mb-3">
                            <Link to={props.visualizeURL} className="page-preview scale-up-2">
                                <img className="bg-primary shadow-lg rounded scale patterBG" src="/assets/images/FilterIcons/map.png" alt="map_view_icon" />
                                <div className="bg-secondary text-center show-on-hover rounded-bottom">
                                    <h6 className="m-0 text-center d-inline-flex align-items-center text-primary">
                                        Show data on map
                                        <svg className="icon icon-xs ms-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z"></path><path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z"></path></svg>
                                    </h6>
                                </div>
                            </Link>
                        </div>
                        <div className="col-3 mb-3">
                            <Link to={props.rawdataURL} className="page-preview scale-up-2">
                                <img className="bg-primary shadow-lg rounded scale patterBG" src="/assets/images/FilterIcons/Timeseries.png" alt="map_view_icon" />
                                <div className="bg-secondary text-center show-on-hover rounded-bottom">
                                    <h6 className="m-0 text-center d-inline-flex align-items-center text-primary">
                                        Timeseries Data
                                        <svg className="icon icon-xs ms-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z"></path><path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z"></path></svg>
                                    </h6>
                                </div>
                            </Link>
                        </div>
                        <div className="col-3 mb-3">
                            <Link to={props.externalURL} className="page-preview scale-up-2">
                                <img className="bg-primary shadow-lg rounded scale patterBG" src="/assets/images/FilterIcons/external.png" alt="map_view_icon" />
                                <div className="bg-secondary text-center show-on-hover rounded-bottom">
                                    <h6 className="m-0 text-center d-inline-flex align-items-center text-primary">
                                        External Data
                                        <svg className="icon icon-xs ms-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z"></path><path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z"></path></svg>
                                    </h6>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default DataRepresentation;