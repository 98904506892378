import React from "react";
import { Route, Routes } from "react-router-dom";
import NotFound from "../../../pages/404Page/404page";
import { OverviewTimeseries } from "../../../pages/FilterBy/Timeseries/TimeseriesFilterOverview";
import { ProductionRaster } from "../../../pages/Sectors/Agriculture/Production/ProductionRaster";
import { ConsumptionRaster } from "../../../pages/Sectors/Agriculture/Consumption/ConsumptionRaster";
import { PricesRaster } from "../../../pages/Sectors/Agriculture/Prices/PricesRasta";
import { LandRaster } from "../../../pages/Sectors/Agriculture/Land/LandRaster";
import { CarbonRaster } from "../../../pages/Sectors/Agriculture/Carbon/CarbonRaster";
import { SoilRaster } from "../../../pages/Sectors/Agriculture/Soil/SoilRaster";
import { OtherEmissionsRaster } from "../../../pages/Sectors/Agriculture/OtherEmissions/OtherEmissionsRaster";
import { WaterAccessRaster } from "../../../pages/Sectors/Water/Access/WaterAccessRaster";
import { AquiferSystemsRaster } from "../../../pages/Sectors/Water/AquiferSytems/AquiferSystemsRaster";
import { WaterConsumptionRaster } from "../../../pages/Sectors/Water/Consumption/WaterConsumptionRaster";
import { DamsRaster } from "../../../pages/Sectors/Water/Dams/DamsRaster";
import { DemandRaster } from "../../../pages/Sectors/Water/Demand/DemandRaster";
import { EcosystemRaster } from "../../../pages/Sectors/Water/Ecosystem/EcosystemRaster";
import { LakesRaster } from "../../../pages/Sectors/Water/Lakes/LakesRaster";
import { WaterSourcesRaster } from "../../../pages/Sectors/Water/Sources/WaterSourcesRaster";
import { WatershedsRaster } from "../../../pages/Sectors/Water/Watersheds/WatershedsRaster";
import { EnergyAccessRaster } from "../../../pages/Sectors/Energy/Access/EnergyAccessRaster";
import { ConsDemRaster } from "../../../pages/Sectors/Energy/ConsumptionDemand/EnergyConsDemRaster";
import { EnergyInfrastructureRaster } from "../../../pages/Sectors/Energy/Infrastructure/EnergyInfrastructureRaster";
import { ModelsRaster } from "../../../pages/Sectors/Energy/Models/ModelsRaster";
import { ProjectsRaster } from "../../../pages/Sectors/Energy/Projects/ProjectsRaster";
import { EnergySourceRaster } from "../../../pages/Sectors/Energy/Sources/EnergySourcesRaster";
import { GDP_GRRaster } from "../../../pages/Sectors/MacroEconomics/GDP_GR/GDP_GRRaster";
import { GDPRaster } from "../../../pages/Sectors/MacroEconomics/GDP/GDPRaster";
import { IncomeRaster } from "../../../pages/Sectors/MacroEconomics/Income/IncomeRaster";
import { IndustryRaster } from "../../../pages/Sectors/MacroEconomics/Industry/IndustryRaster";
import { InfrastructureRaster } from "../../../pages/Sectors/MacroEconomics/Infrastructure/InfrastructureRaster";
import { OccupationRaster } from "../../../pages/Sectors/MacroEconomics/Occupation/OccupationRaster";
import { PopulationRaster } from "../../../pages/Sectors/MacroEconomics/Population/PopulationRaster";
import { ClimateProjectionRaster } from "../../../pages/Sectors/Climate/ClimateProjection/ClimateProjectionRaster";
import { WeatherForecastRaster } from "../../../pages/Sectors/Climate/WeatherForecast/WeatherForecastRaster";
import { WeatherObservationRaster } from "../../../pages/Sectors/Climate/WeatherObservation/WeatherObservationRaster";
import { OverviewRaster } from "../../../pages/FilterBy/Raster/RasterFilterOverview";

export const FilterRasterRoutes = () => {
    return (
        <Routes>
            <Route path="overview" element={<OverviewRaster />} />
            <Route path="agriculture/production/raster" element={<ProductionRaster />} />
            <Route path="agriculture/production/raster/:name" element={<ProductionRaster />} />
            <Route path="agriculture/consumption/raster" element={<ConsumptionRaster />} />
            <Route path="agriculture/consumption/raster/:name" element={<ConsumptionRaster />} />
            <Route path="agriculture/prices/raster" element={<PricesRaster />} />
            <Route path="agriculture/prices/raster/:name" element={<PricesRaster />} />
            <Route path="agriculture/land/raster" element={<LandRaster />} />
            <Route path="agriculture/land/raster/:name" element={<LandRaster />} />
            <Route path="agriculture/carbon/raster" element={<CarbonRaster />} />
            <Route path="agriculture/carbon/raster/:name" element={<CarbonRaster />} />
            <Route path="agriculture/soil/raster" element={<SoilRaster />} />
            <Route path="agriculture/soil/raster/:name" element={<SoilRaster />} />
            <Route path="agriculture/other-emissions/raster" element={<OtherEmissionsRaster />} />
            <Route path="agriculture/other-emissions/raster/:name" element={<OtherEmissionsRaster />} />
            <Route path="water/access/raster" element={<WaterAccessRaster />} />
            <Route path="water/access/raster/:name" element={<WaterAccessRaster />} />
            <Route path="water/aquifer-systems/raster" element={<AquiferSystemsRaster />} />
            <Route path="water/aquifer-systems/raster/:name" element={<AquiferSystemsRaster />} />
            <Route path="water/consumption/raster" element={<WaterConsumptionRaster />} />
            <Route path="water/dams/raster" element={<DamsRaster />} />
            <Route path="water/dams/raster/:name" element={<DamsRaster />} />
            <Route path="water/demand/raster" element={<DemandRaster />} />
            <Route path="water/demand/raster/:name" element={<DemandRaster />} />
            <Route path="water/ecosystems/raster" element={<EcosystemRaster />} />
            <Route path="water/ecosystems/raster/:name" element={<EcosystemRaster />} />
            <Route path="water/lakes/raster" element={<LakesRaster />} />
            <Route path="water/lakes/raster/:name" element={<LakesRaster />} />
            <Route path="water/sources/raster" element={<WaterSourcesRaster />} />
            <Route path="water/sources/raster/:name" element={<WaterSourcesRaster />} />
            <Route path="water/watersheds/raster" element={<WatershedsRaster />} />
            <Route path="water/watersheds/raster/:name" element={<WatershedsRaster />} />
            <Route path="energy/access/raster" element={<EnergyAccessRaster />} />
            <Route path="energy/access/raster/:name" element={<EnergyAccessRaster />} />
            <Route path="energy/consumption-demand/raster" element={<ConsDemRaster />} />
            <Route path="energy/consumption-demand/raster/:name" element={<ConsDemRaster />} />
            <Route path="energy/infrastructure/raster" element={<EnergyInfrastructureRaster />} />
            <Route path="energy/infrastructure/raster/:name" element={<EnergyInfrastructureRaster />} />
            <Route path="energy/models/raster" element={<ModelsRaster />} />
            <Route path="energy/models/raster/:name" element={<ModelsRaster />} />
            <Route path="energy/projects/raster" element={<ProjectsRaster />} />
            <Route path="energy/projects/raster/:name" element={<ProjectsRaster />} />
            <Route path="energy/sources/raster" element={<EnergySourceRaster />} />
            <Route path="energy/sources/raster/:name" element={<EnergySourceRaster />} />
            <Route path="macro-economics/gdp/raster" element={<GDPRaster />} />
            <Route path="macro-economics/gdp/raster/:name" element={<GDPRaster />} />
            <Route path="macro-economics/gdp-growth-rate/raster" element={<GDP_GRRaster />} />
            <Route path="macro-economics/gdp-growth-rate/raster/:name" element={<GDP_GRRaster />} />
            <Route path="macro-economics/income/raster" element={<IncomeRaster />} />
            <Route path="macro-economics/income/raster/:name" element={<IncomeRaster />} />
            <Route path="macro-economics/industry/raster" element={<IndustryRaster />} />
            <Route path="macro-economics/industry/raster/:name" element={<IndustryRaster />} />
            <Route path="macro-economics/infrastructure/raster" element={<InfrastructureRaster />} />
            <Route path="macro-economics/infrastructure/raster/:name" element={<InfrastructureRaster />} />
            <Route path="macro-economics/occupation/raster" element={<OccupationRaster />} />
            <Route path="macro-economics/occupation/raster/:name" element={<OccupationRaster />} />
            <Route path="macro-economics/population/raster" element={<PopulationRaster />} />
            <Route path="macro-economics/population/raster/:name" element={<PopulationRaster />} />
            <Route path="climate/climate-projections/raster" element={<ClimateProjectionRaster />} />
            <Route path="climate/climate-projections/raster/:name" element={<ClimateProjectionRaster />} />
            <Route path="climate/weather-forecasts/raster" element={<WeatherForecastRaster />} />
            <Route path="climate/weather-forecasts/raster/:name" element={<WeatherForecastRaster />} />
            <Route path="climate/weather-observations/raster" element={<WeatherObservationRaster />} />
            <Route path="climate/weather-observations/raster/:name" element={<WeatherObservationRaster />} />
            <Route path="*" element={<NotFound />} />
        </Routes >
    )
}