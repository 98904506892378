import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import axios from "axios";
import { object, string, boolean } from 'yup'
import { Link } from "react-router-dom";

type formValues = { email: string; }

const validationSchema = object().shape({
    email: string().email('Invalid email').required('Email is required')
});

export const ForgotPassword = () => {
    const navigate = useNavigate();
    const [error, setError] = useState("");

    useEffect(() => {
        const token = localStorage.getItem('token')
        if (token){
            navigate('/sectors')
        }
    }, [])

    const formik = useFormik<formValues>({
        initialValues: {
            email: "",
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            alert("Click okay and check your email for a reset link.");
            navigate('/sectors')
            axios.post(`${process.env.REACT_APP_API_URL}/reset-password/`, values)
            .then(response => {
                if (response.data['status'] === "failed"){
                    navigate('/sectors', {state: {message: response.data['message']}})
                    return
                }
                if (response.data['status'] === "success"){
                    navigate('/login')
                }
            })
            .catch(error => {
                alert("Oops, something went wrong, try again later")
                return
             })
        },
    })

    return (
        <>
            <main>
                <section className="vh-lg-100 mt-5 mt-lg-0 bg-soft d-flex align-items-center">
                    <div className="container">
                        <p className="text-center">
                            <Link to="/" className="d-flex align-items-center justify-content-center">
                                <svg className="icon icon-xs me-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z" clipRule="evenodd"></path></svg>
                                Back to homepage
                            </Link>
                        </p>
                        <div className="row justify-content-center form-bg-image" data-background-lg="/static/assets/img/illustrations/signin.svg">
                            <div className="col-12 d-flex align-items-center justify-content-center">
                                <div className="bg-white shadow border-0 rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                                    {error ? <div className="alert alert-danger text-center" role="alert">{error}</div> : null}
                                    <div className="text-center text-md-center mb-4 mt-md-0">
                                        <p className="mb-0">
                                            Please enter and submit your email to reset your password
                                        </p>
                                    </div>
                                    <form method="post" action="" className="mt-4" onSubmit={formik.handleSubmit}>
                                        <div className="form-group mb-4">
                                            <label htmlFor="email">Email</label>
                                            <div className="input-group">
                                                <span className="input-group-text" id="basic-addon1">
                                                    <span className="fas fa-user-circle">
                                                        <svg className="icon icon-xs text-gray-600" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path><path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path></svg>
                                                    </span>
                                                </span>
                                                <input
                                                    type="email"
                                                    name="email"
                                                    id="email"
                                                    placeholder="example@mail.com"
                                                    className="form-control"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.email}
                                                />
                                            </div>
                                            {formik.touched.email && formik.errors.email ? (
                                                <div className="alert alert-danger p-1 mt-1" role="alert">
                                                    {formik.errors.email}
                                                </div>
                                            ) : null}
                                        </div>

                                        <div className="d-grid">
                                            <button type="submit" name="login" className="btn btn-gray-800">Submit email</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    )
}