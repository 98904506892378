import React from 'react'
import { Routes, Route } from 'react-router-dom'
import NotFound from '../../../pages/404Page/404page';
import { MacroEconomicsOverview } from '../../Overview/MacroEconomicsOverview';
import { GDPTimeseries } from '../../../pages/Sectors/MacroEconomics/GDP/GDPTimeseries';
import { GDPRaster } from '../../../pages/Sectors/MacroEconomics/GDP/GDPRaster';
import { GDPExternal } from '../../../pages/Sectors/MacroEconomics/GDP/GDPExternal';
import { GDP_GRTimeseries } from '../../../pages/Sectors/MacroEconomics/GDP_GR/GDP_GRTimeseries';
import { GDP_GRRaster } from '../../../pages/Sectors/MacroEconomics/GDP_GR/GDP_GRRaster';
import { GDP_GRExternal } from '../../../pages/Sectors/MacroEconomics/GDP_GR/GDP_GRExternal';
import { IncomeTimeseries } from '../../../pages/Sectors/MacroEconomics/Income/IncomeTimeseries';
import { IncomeRaster } from '../../../pages/Sectors/MacroEconomics/Income/IncomeRaster';
import { IncomeExternal } from '../../../pages/Sectors/MacroEconomics/Income/IncomeExternal';
import { IndustryTimeseries } from '../../../pages/Sectors/MacroEconomics/Industry/IndustryTimeseries';
import { IndustryRaster } from '../../../pages/Sectors/MacroEconomics/Industry/IndustryRaster';
import { IndustryExternal } from '../../../pages/Sectors/MacroEconomics/Industry/IndustryExternal';
import { InfrastructureTimeseries } from '../../../pages/Sectors/MacroEconomics/Infrastructure/InfrastructureTimeseries';
import { InfrastructureRaster } from '../../../pages/Sectors/MacroEconomics/Infrastructure/InfrastructureRaster';
import { InfrastructureExternal } from '../../../pages/Sectors/MacroEconomics/Infrastructure/InfrastructureExternal';
import { OccupationTimeseries } from '../../../pages/Sectors/MacroEconomics/Occupation/OccupationTimeseries';
import { OccupationRaster } from '../../../pages/Sectors/MacroEconomics/Occupation/OccupationRaster';
import { OccupationExternal } from '../../../pages/Sectors/MacroEconomics/Occupation/OccupationExternal';
import { PopulationTimeseries } from '../../../pages/Sectors/MacroEconomics/Population/PopulationTimeseries';
import { PopulationRaster } from '../../../pages/Sectors/MacroEconomics/Population/PopulationRaster';
import { PopulationExternal } from '../../../pages/Sectors/MacroEconomics/Population/PopulationExternal';

const MacroEconomicsRoutes = () => {
    return (
        <Routes>
            <Route path="" element={<MacroEconomicsOverview />} />
            <Route path="gdp/timeseries" element={<GDPTimeseries />} />
            <Route path="gdp/raster" element={<GDPRaster />} />
            <Route path="gdp/raster/:name" element={<GDPRaster />} />
            <Route path="gdp/external" element={<GDPExternal />} />
            <Route path="gdp-growth-rate/timeseries" element={<GDP_GRTimeseries />} />
            <Route path="gdp-growth-rate/raster" element={<GDP_GRRaster />} />
            <Route path="gdp-growth-rate/raster/:name" element={<GDP_GRRaster />} />
            <Route path="gdp-growth-rate/external" element={<GDP_GRExternal />} />
            <Route path="income/timeseries" element={<IncomeTimeseries />} />
            <Route path="income/raster" element={<IncomeRaster />} />
            <Route path="income/raster/:name" element={<IncomeRaster />} />
            <Route path="income/external" element={<IncomeExternal />} />
            <Route path="industry/timeseries" element={<IndustryTimeseries />} />
            <Route path="industry/raster" element={<IndustryRaster />} />
            <Route path="industry/raster/:name" element={<IndustryRaster />} />
            <Route path="industry/external" element={<IndustryExternal />} />
            <Route path="infrastructure/timeseries" element={<InfrastructureTimeseries />} />
            <Route path="infrastructure/raster" element={<InfrastructureRaster />} />
            <Route path="infrastructure/raster/:name" element={<InfrastructureRaster />} />
            <Route path="infrastructure/external" element={<InfrastructureExternal />} />
            <Route path="occupation/timeseries" element={<OccupationTimeseries />} />
            <Route path="occupation/raster" element={<OccupationRaster />} />
            <Route path="occupation/raster/:name" element={<OccupationRaster />} />
            <Route path="occupation/external" element={<OccupationExternal />} />
            <Route path="population/timeseries" element={<PopulationTimeseries />} />
            <Route path="population/raster" element={<PopulationRaster />} />
            <Route path="population/raster/:name" element={<PopulationRaster />} />
            <Route path="population/external" element={<PopulationExternal />} />
            <Route path="*" element={<NotFound />} />
        </Routes>
    )
}

export default MacroEconomicsRoutes;