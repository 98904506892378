import React from "react";
import { Link } from "react-router-dom";

interface OverviewProps {
    sector: string
    sources: {
        title: string
        description: string
        dataPageURL: string
    }[]
}

export const Overview = (props: OverviewProps) => {
    return (
        <>
            <div className="row justify-content-center mb-5 px-2 mt-2">
                <div className="col-12 text-center">
                    <h2 className="h1 px-lg-5">{props.sector} Sector</h2>
                    <p className="lead px-lg-10 mb-1">Find below all timeseries data sources available relating to {props.sector} Sector.</p>
                    <p className="lead px-lg-10 mb-1">Visit the link of each data source of your interest to be led to the data page.</p>
                </div>
            </div>
            <div className="card card-body border-0 shadow mb-4 mb-xl-0">
                <p className="small pe-4 mb-2 text-info">To access a data source, click the button below each data source to get to the data page.</p>
                <hr />
                <ul className="list-group list-group-flush">
                    {props.sources.map((source, index) => {
                        return (
                            <li key={index} className="list-group-item d-flex align-items-center justify-content-between px-0 border-bottom">
                                <div>
                                    <div className="d-flex">
                                        <p className="fw-extrabold fs-5 mb-0">{source.title}</p>
                                    </div>
                                    <p className="small pe-4 mb-2">{source.description}</p>
                                    <Link to={source.dataPageURL}>
                                        <button type="button" className="btn btn-primary mb-2 text-white">
                                            Data Page
                                        </button>
                                    </Link>
                                </div>
                            </li>
                        )
                    })}
                </ul>
            </div>
        </>
    )
}