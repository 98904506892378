import React, {useEffect, useState} from "react";
import { getLayers } from "./GetLayers";
import * as arv from "./Raster/Variables/Agriculture/AgriculturalRasterVariables";

import * as clv from "./Raster/Variables/Climate/ClimateRasterVariables";

import * as erv from "./Raster/Variables/Energy/EnergyRasterVariables";

import * as mrv from "./Raster/Variables/MacroEconomics/MacroEconomicsRasterVariables";

import * as wrv from "./Raster/Variables/Water/WaterRasterVariables";


export const useFetchLayers = () => {
    const [Layers, setLayers] = useState<Array<string | null>>([]);

    useEffect(() => {
        const allVariables = [
            // Agricultural Raster Variables
            ...arv.CarbonRasterVariables, 
            ...arv.ConsumptionRasterVariables, 
            ...arv.LandRasterVariables, 
            ...arv.OtherEmissionsRasterVariables, 
            ...arv.PricesRasterVariables, 
            ...arv.ProductionRasterVariables, 
            ...arv.SoilRasterVariables,

            // Climate Raster Variables
            ...clv.ClimateProjectionsRasterVariables, 
            ...clv.WeatherForecastRasterVariables, 
            ...clv.WeatherObservationRasterVariables,

            // Energy Raster Variables
            ...erv.EnergyAccessRasterVariables, 
            ...erv.EnergyConsumptionDemandRasterVariables, 
            ...erv.EnergyInfrastructureRasterVariables, 
            ...erv.ModelsRasterVariables, 
            ...erv.EnergySourcesRasterVariables, 
            ...erv.ProjectsRasterVariables,

            // MacroEconomics Raster Variables
            ...mrv.GDPRasterVariables, 
            ...mrv.GDP_GRRasterVariables, 
            ...mrv.IncomeRasterVariables, 
            ...mrv.IndustryRasterVariables, 
            ...mrv.InfrastructureRasterVariables, 
            ...mrv.OccupationRasterVariables, 
            ...mrv.PopulationRasterVariables,

            // Water Raster Variables
            ...wrv.WaterAccessRasterVariables, 
            ...wrv.AquiferSystemsRasterVariables, 
            ...wrv.WaterConsumptionRasterVariables, 
            ...wrv.DamsRasterVariables, 
            ...wrv.DemandRasterVariables, 
            ...wrv.EcosystemsRasterVariables, 
            ...wrv.LakesRasterVariables, 
            ...wrv.SourcesRasterVariables, 
            ...wrv.WatershedsRasterVariables
        ]

        const url = window.location.href.split('/');
        let dataSource;
        if (url[url.length - 1] === ""){
            dataSource = (url[url.length - 2]).replaceAll('_', ' ')
        } else {
            dataSource = (url[url.length - 1]).replaceAll('_', ' ')
        }
        for (let i = 0; i < allVariables.length; i++) {
            if (allVariables[i].startsWith(dataSource)) {
                (async function(){
                    const lays = await getLayers(dataSource, allVariables[i].split('(')[1].slice(0, -1))
                    setLayers(lays!)
                })();
                break;
            }
        }
    }, [])
    
    return Layers;
}