import React from "react";

export const WaterAccessUrls: string[][] = []

export const AquiferSystemsUrls: string[][] = []

export const WaterConsumptionUrls: string[][] = [
    ["Water Usage", "https://data.apps.fao.org/aquastat/?lang=en", "AQUASTAT Dissemination System"]
]

export const DamsUrls: string[][] = []

export const DemandUrls: string[][] = [
    ["Water Demand", "https://data.apps.fao.org/aquastat/?lang=en", "AQUASTAT Dissemination System"]
]

export const EcosystemsUrls: string[][] = []

export const LakesUrls: string[][] = []

export const SourcesUrls: string[][] = []

export const WatershedsUrls: string[][] = [
    ["Watersheds", "https://data.apps.fao.org/aquastat/?lang=en", "AQUASTAT Dissemination System"]
]
